import { CardScroller } from 'components/Scroller/CardScroller'
import { ClubContext } from 'pages/club/[club_slug]'
import React, { useContext, useEffect, useState } from 'react'

const TeamView: React.FC = (props: any) => {
    const { initialData } = props
    const club = useContext(ClubContext)
    const teams = initialData?.initialData?.club?.teams

    const [data, setData] = useState<null | Array<any>>(teams)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (club.teams) {
            setData(club.teams)
        }
        setLoading(false)
    }, [club])

    return (
        <CardScroller
            title="Our Teams"
            data={
                data
                    ? data.map((t) => {
                          return {
                              title: t.name,
                              image: t?.image ?? club?.logo,
                              slug: t.slug,
                              href: `/club/${club.slug}/team/${t.slug}`,
                          }
                      })
                    : []
            }
            type="League"
            seeAllLink={`/club/${club.slug}/teams`}
        />
    )
}

export default TeamView
