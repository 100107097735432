import { useMutation, useSubscription } from '@apollo/client'
import { Form, Popover, Tooltip } from 'antd'
import EditIcon from 'assets/icon/edit'
import photo from 'assets/images/layout/group.png'
import { PlayerAvatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Row } from 'components/Layout'
import ButtonLoading from 'components/Loading/ButtonLoading'
import ImageCrop_Modal from 'components/Modal/ImageCrop'
import { SocialShare } from 'components/SocialShare'
import { Text } from 'components/Text'
import { ADMINQL } from 'graphql/club'
import { mutate, subscribe } from 'graphql/user'
import User from 'hooks/user'
import { playerEvent } from 'lib/tracking/events/player'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { PlayerContext } from 'pages/club/[club_slug]/player/[player_slug]'
import { useContext, useEffect, useRef, useState } from 'react'
import { FiSave, FiShare2, FiUserMinus, FiUserPlus } from 'react-icons/fi'
import { ImCancelCircle } from 'react-icons/im'
import { toast } from 'react-toastify'
import { baseUrl } from 'utils/constData'
import { isServer } from 'utils/helper'
import { s3UploadFile } from 'utils/s3-helper'
import {
    ContentWrapper,
    CustomForm,
    CustomInput,
    CustomText,
    DeskProfileWrapper,
    FlexWrapper,
    PlayerBtn,
} from './Intro.style'

type Props = {
    option: {
        onClickFollow(value: any): void
        teams: any
        show: boolean
        flag: boolean
        setFlag(e: boolean): void
        imageSrc: string
    }
}

const DeskProfile = (props: Props) => {
    const [form] = Form.useForm()
    const { player } = useContext<any>(PlayerContext)
    const router = useRouter()
    const { asPath } = router
    const { teams, onClickFollow, show, flag, setFlag, imageSrc } = props.option

    const { user }: { user: any } = User()
    const tlist = teams
        ? teams.map((item: any) => ({ label: item.name, value: item.id }))
        : []

    const [meta, setMeta] = useState<any>(null)
    const [file, setFile] = useState<any>(null)
    const [displayImage, setDisplayImage] = useState<any>(photo)
    const [currentImage, setCurrentImage] = useState<any>(photo)
    const [isSubmit, setSubmiting] = useState<boolean>(false)
    const [firstName, setFirstName] = useState<any>()
    const [lastName, setLastName] = useState<any>()
    const [photoFlag, setPhotoFlag] = useState<boolean>(false)
    const [followIds, setFollowIds] = useState<Array<any>>([])
    const fileInputRef = useRef<HTMLInputElement>(null)
    // susbscribe to user follow
    useSubscription(subscribe.PLAYER_USER_FOLLOW, {
        skip: isServer,
        variables: {
            where: { player_detail_id: { _eq: player?.id } },
        },
        onData: ({ data: { data, loading } }) => {
            !loading &&
                data.user_player_follows &&
                setFollowIds(
                    data.user_player_follows.map(
                        (usr: { user_id: any }) => usr.user_id,
                    ),
                )
        },
    })

    const [follow] = useMutation(mutate.USER_FOLLOW_PLAYER)
    const [unfollow] = useMutation(mutate.USER_UNFOLLOW_PLAYER)
    const followed = (): boolean => followIds.includes(user?.id)

    useEffect(() => {
        setDisplayImage(player?.image ? player?.image : imageSrc)
        setCurrentImage(player?.image ? player?.image : imageSrc)
        form.setFieldsValue({
            first_name: player?.user?.first_name
                ? player?.user?.first_name
                : player?.name,
            last_name: player?.user?.last_name
                ? player?.user?.last_name
                : player?.last_name,
        })
    }, [player])

    const onChangeFirstName = (e: { target: { value: any } }) => {
        setFirstName(e.target.value)
    }

    const onChangeLastName = (e: { target: { value: any } }) => {
        setLastName(e.target.value)
    }

    const savePhoto = async (file: File, imageSrc: any) => {
        setFile(file)
        setDisplayImage(imageSrc)
        setFlag(true)
    }
    const onFileInputChange = (e: any) => {
        setMeta(e)
        setPhotoFlag(true)
    }

    const onChangePhoto = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }

    const [updateUser] = useMutation(ADMINQL.UPDATE_PLAYER_USER_BY_ID)

    const [update] = useMutation(ADMINQL.UPDATE_PLAER_BY_ID, {
        onCompleted() {
            setFlag(false)
            setCurrentImage(displayImage)
            toast.success('Player details updated.')
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const onFinish = async (values: any) => {
        setSubmiting(true)
        const slug: string = player?.slug
        let image: string | null = null

        if (!_.isNull(file)) {
            const s3res: any = await s3UploadFile('Players', slug, file)
            image = s3res.location
        } else {
            if (!_.isEmpty(player?.image)) {
                image = player?.image
            }
        }

        if (!_.isNull(player?.user_id)) {
            await updateUser({
                variables: {
                    id: player?.user_id,
                    object: {
                        first_name: firstName,
                        last_name: lastName,
                        photo: image,
                    },
                },
            })
        }

        await update({
            variables: {
                id: player?.id,
                object: {
                    name: firstName,
                    last_name: lastName,
                    slug,
                    image,
                    club_id: player?.club.id,
                    prev_club: '',
                },
            },
        })

        setSubmiting(false)
    }

    const onCancelUpdate = () => {
        setDisplayImage(currentImage)
        setFlag(false)
    }

    if (!player) {
        return <></> //add loader or skeleton loader
    }

    const handleFollow = () => {
        if (user) {
            if (followed()) {
                unfollow({
                    variables: { where: { user_id: { _eq: user?.id } } },
                })
            } else {
                follow({
                    variables: {
                        objects: {
                            player_detail_id: player.id,
                            user_id: user?.id,
                        },
                    },
                })
            }
        } else {
            router.push(`/api/auth/login?returnTo=${asPath}`)
        }
    }

    const trackEdit = () => {
        playerEvent({
            props: {
                playerName: `${player?.user?.first_name}  ${player?.user?.last_name}`,
                message: 'Edit Details',
            },
        })
    }
    const trackShare = () => {
        playerEvent({
            props: {
                playerName: `${player?.user?.first_name}  ${player?.user?.last_name}`,
                message: 'Share Player Profile',
            },
        })
    }
    return (
        <DeskProfileWrapper>
            <FlexWrapper direction="row" justify="flex-start">
                <FlexWrapper maxWidth="200px">
                    <PlayerAvatar src={displayImage} size={180} />
                    {user && user?.user_role_id <= 4 && (
                        <Button
                            bColor="primary"
                            bSize="small"
                            type="button"
                            className="actionBtn"
                            disabled={isSubmit}
                            style={{ marginTop: '15px' }}
                            onClick={() => {
                                onChangePhoto()
                            }}
                        >
                            {'Upload Photo'}
                        </Button>
                    )}

                    <input
                        onChange={onFileInputChange}
                        onClick={(event: any) => {
                            event.target.value = null
                        }}
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                    />
                </FlexWrapper>

                <FlexWrapper direction="row" justify="flex-start">
                    <div
                        style={{
                            display: player.bio || flag ? 'flex' : '',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {!flag ? (
                            <FlexWrapper direction="row" justify="flex-start">
                                <Text strong fColor="white" fSize={2} mode="h1">
                                    {player?.name && player?.last_name
                                        ? `${player?.name ?? ''} ${
                                              player?.last_name ?? ''
                                          }`
                                        : `${player?.user?.first_name ?? ''} ${
                                              player?.user?.last_name ?? ''
                                          }`}
                                </Text>
                            </FlexWrapper>
                        ) : (
                            <FlexWrapper direction="row">
                                <FlexWrapper direction="row">
                                    <Form
                                        name="basic"
                                        form={form}
                                        layout="horizontal"
                                    >
                                        <Row gap={5}>
                                            <CustomForm.Item
                                                name="first_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'First Name is required.',
                                                    },
                                                ]}
                                            >
                                                <CustomInput
                                                    onChange={onChangeFirstName}
                                                    placeholder="first name"
                                                    disabled={isSubmit}
                                                />
                                            </CustomForm.Item>
                                            <CustomForm.Item
                                                name="last_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Last Name is required.',
                                                    },
                                                ]}
                                            >
                                                <CustomInput
                                                    onChange={onChangeLastName}
                                                    placeholder="last name"
                                                    disabled={isSubmit}
                                                />
                                            </CustomForm.Item>
                                        </Row>
                                    </Form>
                                </FlexWrapper>

                                <FlexWrapper
                                    direction="row"
                                    className="saveActionButton"
                                >
                                    <Button
                                        onClick={onFinish}
                                        bColor="warning"
                                        icon={
                                            isSubmit ? (
                                                <ButtonLoading />
                                            ) : (
                                                <FiSave />
                                            )
                                        }
                                        disabled={isSubmit}
                                        className="actionBtn"
                                    >
                                        {'Save'}
                                    </Button>
                                    <Button
                                        bColor="warning"
                                        icon={<ImCancelCircle />}
                                        onClick={() => onCancelUpdate()}
                                        disabled={isSubmit}
                                        className="actionBtn"
                                    >
                                        {'Cancel'}
                                    </Button>
                                </FlexWrapper>
                            </FlexWrapper>
                        )}

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {player.bio && user && (
                                <CustomText>{'121 Followers'}</CustomText>
                            )}

                            {!flag && (
                                <FlexWrapper
                                    direction="row"
                                    justify="flex-start"
                                >
                                    {user && user?.user_role_id <= 4 && (
                                        <CustomForm.Item>
                                            <Button
                                                bColor="warning"
                                                icon={<EditIcon />}
                                                onClick={() => {
                                                    setFlag(true), trackEdit()
                                                }}
                                                className="actionBtn"
                                            >
                                                {'Edit'}
                                            </Button>
                                        </CustomForm.Item>
                                    )}

                                    <PlayerBtn>
                                        <Tooltip
                                            trigger={
                                                !user && show ? 'click' : 'none'
                                            }
                                            placement="bottom"
                                            title="Please log in or sign up to Follow Players"
                                            color={'#202022'}
                                        >
                                            <Button
                                                bColor={
                                                    followed()
                                                        ? 'gray'
                                                        : 'warning'
                                                }
                                                icon={
                                                    followed() ? (
                                                        <FiUserMinus />
                                                    ) : (
                                                        <FiUserPlus />
                                                    )
                                                }
                                                onClick={() => handleFollow()}
                                                className="actionBtn"
                                            >
                                                {followed()
                                                    ? 'Unfollow'
                                                    : 'Follow Player'}
                                            </Button>
                                        </Tooltip>
                                    </PlayerBtn>
                                    <Popover
                                        content={
                                            <SocialShare
                                                url={`${
                                                    baseUrl + router.asPath
                                                }`}
                                            />
                                        }
                                        trigger="click"
                                    >
                                        <Button
                                            bColor="primary"
                                            bSize="small"
                                            icon={<FiShare2 />}
                                            className="actionBtn"
                                            onClick={trackShare}
                                        >
                                            {'Share'}
                                        </Button>
                                    </Popover>
                                </FlexWrapper>
                            )}
                        </div>
                        {/* /end */}
                    </div>

                    {user && player.bio && (
                        <ContentWrapper show={true}>
                            <Row
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <CustomText>{player.bio}</CustomText>
                            </Row>
                        </ContentWrapper>
                    )}
                </FlexWrapper>
            </FlexWrapper>
            <ImageCrop_Modal
                show={photoFlag}
                meta={meta}
                saveImage={savePhoto}
                handleClose={() => setPhotoFlag(false)}
            />
        </DeskProfileWrapper>
    )
}

export default DeskProfile
