import { themeGet } from '@styled-system/theme-get'
import { Button } from 'components/Button'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const ModalWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    align-items: center;
    z-index: 160;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
                  animation: modalWrapperFadeIn 0.3s ease-out;
              `
            : css`
                  visibility: hidden;
              `};

    @keyframes modalWrapperFadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const ModalContent = styled.section<{ show: boolean }>`
    border-radius: 4px;
    transition: all ease 0.5s;
    ${({ show }) =>
        show
            ? css`
                  display: block;
                  animation: modalContentFadeIn 0.3s ease-out;
              `
            : css`
                  display: none;
              `};
    padding: 0;
    position: fixed;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88%;

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        width: 100%;
    }

    @keyframes modalContentFadeIn {
        0% {
            opacity: 0;
            scale: 0.85;
            // Need to adjust position to account for scaling: 0.85 * 50% = 42.5%
            top: 42.5%;
            left: 42.5%;
        }
        100% {
            opacity: 1;
            scale: 1;
        }
    }
`

export const ModalBody = styled.article`
    background-color: ${themeGet('colors.black.regular')};
`

export const ModalHeader = styled.article`
    padding: 5px;
    display: flex;
    justify-content: right;
    background-color: rgb(25 24 26);
    border-radius: 5px 5px 0 0;
`

export const ModalFooter = styled.article`
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
    background-color: rgb(25 24 26);
    .footer-text {
        font-size: 25px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 15px;
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 10px;
    }
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    max-width: 60%;
    height: fit-content;
    margin: auto 0;
    row-gap: 10px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        max-width: 100%;
        justify-content: center;
        margin: 0;
    }
`
export const ProgressWrapper = styled.div`
    margin-right: 10px;
    .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: red;
    }
    .ant-progress-circle .ant-progress-text {
        color: white;
    }
`

export const DesktopButtonWrapper = styled.div`
    margin-right: 10px;
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #a2a2a2;
    gap: 4px;
    a {
        font-size: 1.4em;
        font-weight: 550;
    }
    .footer-text-details {
        font-size: 1.15em;
        font-weight: 550;
    }
    .footer-createdby {
        font-size: 1.15em;
        font-weight: 550;
        color: red;
    }
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        .footer-text-details {
            font-size: 1.1em;
        }
    }
`

export const FeatureClipButton = styled(Button)`
    border: 1px solid rgb(39, 39, 42);
    font-size: 1.5em;
`

export const ClubLinkWrapper = styled.div`
    font-size: 1.15em;
    padding-top: 4px;
    line-height: 1.45em;
`

export const EditClipNameButton = styled(Button)`
    width: 28px;
    height: 28px;
    border: none;
    border: 1px solid rgb(39, 39, 42);
    padding: 16px;
    margin: 0;
`
export const ClipDetailWrapper = styled.article`
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;
    /* background-color: rgb(25 24 26); */
    .footer-text {
        font-size: 25px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 15px;
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 10px;
    }
`
