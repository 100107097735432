import { Select } from 'antd'
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import { StreamPageContext } from 'hooks/context/StreamPageContext'
import { useContext } from 'react'
import { TaggingFilesContext } from './TaggingLayout'
const { Option } = Select

const TagClub = () => {
    const { home_name, away_name, home_club_id, away_club_id } =
        useContext(StreamPageContext)
    const { selectedClubId, editMode, setSelectedClubId } =
        useContext(TaggingFilesContext)

    const handleOnChange = async (clubId: number) => {
        setSelectedClubId(clubId)
    }

    return (
        <Row rWidth={'50%'} gap={5}>
            <Row rWidth={'20%'}>
                <Text fSize={0.8}>{'Tag Club:'}</Text>
            </Row>
            <Row rWidth={'80%'}>
                {editMode ? (
                    <Select
                        value={selectedClubId}
                        style={{ width: '100%' }}
                        placeholder="--Select Club--"
                        onChange={handleOnChange}
                    >
                        <Option key={home_club_id} value={home_club_id}>
                            {home_name}
                        </Option>
                        <Option key={away_club_id} value={away_club_id}>
                            {away_name}
                        </Option>
                    </Select>
                ) : (
                    <Text fSize={0.8}>
                        {selectedClubId === home_club_id
                            ? home_name
                            : away_name}
                    </Text>
                )}
            </Row>
        </Row>
    )
}

export default TagClub
