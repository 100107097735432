import themeGet from '@styled-system/theme-get'
import Link from 'next/link'
import { ReactNode } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import styled from 'styled-components'

export interface SeeAllProps {
    href: string
    children?: ReactNode
}

export function SeeAll(props: any) {
    const { href, children } = props

    return (
        <Link href={href} passHref legacyBehavior>
            <SeeAllWrapper>
                {children ?? (
                    <>
                        <p>See all</p>
                        <IoIosArrowForward />
                    </>
                )}
            </SeeAllWrapper>
        </Link>
    );
}

const SeeAllWrapper = styled.a`
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;

    transition-duration: 0s;

    color: ${themeGet('colors.gray.200')};
    :hover {
        text-decoration: underline;
        color: ${themeGet('colors.white')};
    }
`
