import * as t from 'redux/types/clip'

export const setClipList = (clip_list: any) => ({
    type: t.SET_CLIP_LIST,
    payload: clip_list,
})

export const setClipPlaybackId = (clip_playbackId: any) => ({
    type: t.SET_CURRENT_CLIP_PLAYBACKID,
    payload: clip_playbackId,
})
