import { PlayerAvatar } from 'components/Avatar'
import { Text } from 'components/Text'
import useMediaQuery from 'hooks/useMediaQuery'
import Link from 'next/link'
import { ReactNode } from 'react'
import {
    ItemCardFooter,
    ItemCardImage,
    ItemCardTile,
    ItemCardWrapper,
} from './ItemCard.style'

const placeholderImg = `https://via.placeholder.com/300.png/023/fff?text=`

export interface CardProps {
    title: string
    subtitle?: string
    image: string | ReactNode
    slug: string
    href: string
    type?: ItemCardType
}

export type ItemCardType = 'League' | 'Club' | 'Team' | 'Avatar'

function ItemCard(props: CardProps) {
    const { title, subtitle, image, href, type } = props
    const isMobile = useMediaQuery(600)

    const fontWeight = type === 'Avatar' ? 600 : 700
    const fontSize = type && ['League', 'Club'].includes(type) ? 1 : 0.875

    return (
        <Link href={href} passHref legacyBehavior>
            <ItemCardWrapper>
                <ItemCardTile className="item-card-tile">
                    {type !== 'Avatar' ? (
                        <ItemCardImage
                            className="item-card-image"
                            src={image ?? placeholderImg}
                            oFit="fill"
                            width={isMobile ? 92 : 108}
                            height={isMobile ? 92 : 108}
                            // alt left intentionally blank, the text below is sufficient
                            alt=""
                        />
                    ) : (
                        <div className="item-card-avatar">
                            <PlayerAvatar src={image} />
                        </div>
                    )}
                </ItemCardTile>
                <ItemCardFooter>
                    <Text
                        fColor={'white'}
                        fSize={fontSize}
                        tAlign={'center'}
                        fWeight={fontWeight}
                    >
                        {title}
                    </Text>
                    {subtitle ? (
                        <Text
                            fColor={'primary.regular'}
                            fSize={0.875}
                            tAlign={'center'}
                            fWeight={500}
                        >
                            {subtitle}
                        </Text>
                    ) : (
                        <></>
                    )}
                </ItemCardFooter>
            </ItemCardWrapper>
        </Link>
    );
}

export default ItemCard
