import React from 'react'
import * as nextShare from 'next-share'
import { toast } from 'react-toastify'

// types/components
import { SocialShareProps } from 'types/components/SocialShare'
import { Button } from 'antd'

// styles
import { ButtonsContainer, SocialShareContainer } from './index.style'

// antd icons
import { CopyOutlined } from '@ant-design/icons'

const SocialShare: React.FC<SocialShareProps> = ({ url }) => {
    const onHandleCopy = () => {
        navigator.clipboard.writeText(`${url}`)
        toast.success('Link copied to clipboard.')
    }
    return (
        <SocialShareContainer>
            <ButtonsContainer>
                <nextShare.FacebookShareButton
                    url={`${url}`}
                    quote={'Streamer.'}
                    hashtag={'#streamer'}
                >
                    <nextShare.FacebookIcon size={32} round />
                </nextShare.FacebookShareButton>

                <nextShare.TwitterShareButton url={`${url}`}>
                    <nextShare.TwitterIcon size={32} round />
                </nextShare.TwitterShareButton>

                <nextShare.WhatsappShareButton url={`${url}`}>
                    <nextShare.WhatsappIcon size={32} round />
                </nextShare.WhatsappShareButton>

                <nextShare.RedditShareButton url={`${url}`}>
                    <nextShare.RedditIcon size={32} round />
                </nextShare.RedditShareButton>
            </ButtonsContainer>

            <ButtonsContainer>
                <nextShare.TelegramShareButton url={`${url}`}>
                    <nextShare.TelegramIcon size={32} round />
                </nextShare.TelegramShareButton>

                <nextShare.LinkedinShareButton url={`${url}`}>
                    <nextShare.LinkedinIcon size={32} round />
                </nextShare.LinkedinShareButton>

                <nextShare.EmailShareButton url={`${url}`}>
                    <nextShare.EmailIcon size={32} round />
                </nextShare.EmailShareButton>

                <Button
                    style={{ backgroundColor: '#7f7f7f' }}
                    onClick={onHandleCopy}
                    icon={<CopyOutlined style={{ color: 'white' }} />}
                    shape="circle"
                />
            </ButtonsContainer>
        </SocialShareContainer>
    )
}
export default SocialShare
