import { useMutation } from '@apollo/client'
import { Button } from 'components/Button'
import { Col } from 'components/Layout'
import { Text } from 'components/Text'
import { mutate } from 'graphql/mediaGallery'
import { toast } from 'react-toastify'
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
    StyledModal,
} from './index.style'

const DeleteMediaModal = (props: any) => {
    const [deleteMedia] = useMutation(mutate.DELETE_GALLERY_IMAGE, {
        onCompleted() {
            props.setShow(false)
            props.setMediaModalShow(false)
            toast.success('Media deleted.')
        },
        onError(e) {
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const handleDelete = () => {
        props.mediaID &&
            deleteMedia({
                variables: {
                    where: { id: { _eq: props.mediaID } },
                },
            })
    }

    return (
        <StyledModal show={props.show}>
            <ModalContent>
                <ModalHeader>
                    <Col>
                        <Text fSize={1.5} fWeight={700} tAlign={'center'}>
                            Delete Media
                        </Text>
                        <Text
                            fSize={1}
                            fWeight={200}
                            tAlign={'center'}
                            style={`
                                color: rgb(153 152 153);
                            `}
                        >
                            Are you sure you want to delete this media.
                        </Text>
                    </Col>
                </ModalHeader>
                <ModalFooter>
                    <Button
                        onClick={() => props.setShow(false)}
                        style={`
                            border: 0;
                            background-color: rgb(87 86 87);
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDelete()}
                        variant="warning"
                        style={`
                            margin-left: 20px;
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </StyledModal>
    )
}

export default DeleteMediaModal
