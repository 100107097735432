import { Popover } from 'antd'
import { Button } from 'components/Button'
import { Row } from 'components/Layout'
import { SocialShare } from 'components/SocialShare'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { FiShare2 } from 'react-icons/fi'
import { ImCancelCircle } from 'react-icons/im'
import { MediaGalleryContext } from 'sections/mediagallery'
import { TaggingLayout } from 'sections/mediagallery/components'
import { VideoGalleryProps } from 'types/components/Modal'
import { baseUrl } from 'utils/constData'
import DeleteMediaModal from '../DeleteMedia'
import {
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
} from './index.style'

const VideoPlayer = dynamic(() => import('components/Video/Bitmovin'), {
    ssr: false,
})

const VideoGallery: React.FC<VideoGalleryProps> = ({
    title,
    show = false,
    handleClose,
    mediaId,
    playbackId,
    clubTagId,
    clubTagName,
    playersTag,
    leagueName,
}) => {
    const router = useRouter()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { isAdminOfClub } = useContext(MediaGalleryContext)
    const handleDeleteClick = () => {
        const videoContainers = document.querySelectorAll(
            '#bitmovinplayer-video-player-container',
        )
        if (videoContainers.length >= 2) {
            const secondVideoContainer = videoContainers[1] as HTMLVideoElement
            secondVideoContainer.pause()
        } else {
            const video = document.getElementById(
                'bitmovinplayer-video-player-container',
            ) as HTMLVideoElement | undefined
            video?.pause()
        }
        setShowDeleteModal(true)
    }
    return (
        <ModalWrapper show={show}>
            <ModalContent show={show}>
                <ModalHeader>
                    <Button
                        style={{
                            backgroundColor: '#1B1B25',
                        }}
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                    />
                </ModalHeader>
                <ModalBody>
                    <Row flexDirection="column" gap={5}>
                        <VideoPlayer
                            key={playbackId}
                            playback_id={playbackId ?? ''}
                            video_title={title}
                            league_name={leagueName}
                            video_type={'Clip'}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <TaggingLayout
                        mediaId={mediaId}
                        clubTagId={clubTagId!}
                        clubTagName={clubTagName!}
                        playersTag={playersTag}
                        modalShown={show}
                    />
                    {isAdminOfClub && (
                        <Button
                            bColor="warning"
                            bSize="small"
                            icon={<ImCancelCircle />}
                            type="button"
                            key={'btn-del'}
                            onClick={handleDeleteClick}
                        >
                            {'Delete'}
                        </Button>
                    )}
                    <Popover
                        content={
                            <SocialShare
                                url={`${baseUrl}${
                                    router.asPath.split('?')[0] === '/'
                                        ? `/media-gallery/`
                                        : router.asPath.split('?')[0] +
                                          `/media-gallery/`
                                }${mediaId}`}
                            />
                        }
                        trigger={'click'}
                    >
                        <Button
                            bColor="primary"
                            bSize="small"
                            icon={<FiShare2 />}
                            className="actionBtn"
                        >
                            {'Share'}
                        </Button>
                    </Popover>
                </ModalFooter>
            </ModalContent>
            <DeleteMediaModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                mediaID={mediaId}
                setMediaModalShow={handleClose}
            />
        </ModalWrapper>
    )
}

export default VideoGallery
