import { gql } from '@apollo/client'

const UPSERT_CLUB_TAG = gql`
    mutation UpsertMediaGalleryClub(
        $objects: [media_gallery_club_insert_input!]!
        $update_columns: [media_gallery_club_update_column!] = []
    ) {
        insert_media_gallery_club(
            objects: $objects
            on_conflict: {
                constraint: media_gallery_club_media_gallery_id_key
                update_columns: $update_columns
            }
        ) {
            affected_rows
            returning {
                id
            }
        }
    }
`

const UPSERT_PLAYER_TAG = gql`
    mutation InsertPlayerTag(
        $objects: [media_gallery_players_insert_input!]!
        $update_columns: [media_gallery_players_update_column!] = []
    ) {
        insert_media_gallery_players(
            objects: $objects
            on_conflict: {
                constraint: media_gallery_players_pkey
                update_columns: $update_columns
            }
        ) {
            affected_rows
            returning {
                id
                media_gallery_id
                player_id
                players_detail {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        }
    }
`

const DELETE_PLAYER_TAG = gql`
    mutation DeletePlayerTag($where: media_gallery_players_bool_exp = {}) {
        delete_media_gallery_players(where: $where) {
            affected_rows
            returning {
                id
                player_id
            }
        }
    }
`

export const ADD_MEDIA_FILE = gql`
    mutation AddMediaFile($objects: [media_gallery_insert_input!]!) {
        insert_media_gallery(objects: $objects) {
            affected_rows
            returning {
                id
                match_id
            }
        }
    }
`

export const ADD_CLUB_TAG = gql`
    mutation AddClubTag($objects: [media_gallery_club_insert_input!]!) {
        insert_media_gallery_club(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const ADD_PLAYER_TAG = gql`
    mutation AddPlayerTag($objects: [media_gallery_players_insert_input!]!) {
        insert_media_gallery_players(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`

const DELETE_GALLERY_IMAGE = gql`
    mutation DeleteGalleryImage($where: media_gallery_bool_exp = {}) {
        delete_media_gallery(where: $where) {
            affected_rows
        }
    }
`

const UPDATE_CLIP_NAME = gql`
    mutation updateClipName(
        $where: clip_assets_bool_exp = {}
        $_set: clip_assets_set_input = {}
    ) {
        update_clip_assets(where: $where, _set: $_set) {
            returning {
                id
                name
            }
        }
    }
`
// ---------
export default {
    UPSERT_CLUB_TAG,
    UPSERT_PLAYER_TAG,

    UPDATE_CLIP_NAME,

    ADD_MEDIA_FILE,
    ADD_CLUB_TAG,
    ADD_PLAYER_TAG,

    DELETE_GALLERY_IMAGE,
    DELETE_PLAYER_TAG,
}
