import ItemCard, { CardProps } from 'components/Card/ItemCard/ItemCard'
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'

const PlayerAllView: React.FC = (props: any) => {
    const { club, players, clubName } = props

    return (
        <>
            <Text fColor="white" fSize={1.375} fWeight={700} mode="h1">
                {`${clubName} Players`}
            </Text>
            <Row display="flex" gap={30} flexWrap="wrap" padding="20px 20px">
                {players &&
                    players.map((player: any, index: number) => {
                        const item: CardProps = {
                            image: player?.user?.photo
                                ? player?.user?.photo
                                : player?.image
                                  ? player?.image
                                  : '',
                            title: player?.user?.first_name
                                ? `${player?.user?.first_name ?? ''} ${
                                      player?.user?.last_name ?? ''
                                  }`
                                : `${player.name} ${player.last_name}`,
                            subtitle: player.team?.name,
                            type: 'Avatar',
                            slug: player.slug,
                            href: `/club/${club.slug}/player/${player.slug}`,
                        }

                        return <ItemCard key={index} {...item} />
                    })}
            </Row>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    club: state.club.info,
    players: state.players.list,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(PlayerAllView)
