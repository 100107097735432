const CreateUploadURL = async () => {
    try {
        return fetch('/api/mediaGallery/upload', {
            method: 'POST',
        })
            .then((res) => res.json())
            .then(({ data }) => {
                return data
            })
    } catch (e) {
        console.error('Error in createUpload', e)
    }
}

export default CreateUploadURL
