import { useSubscription } from '@apollo/client'
import { Tooltip } from 'antd'
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import { subscribe } from 'graphql/mediaGallery'
import _ from 'lodash'
import React from 'react'
import { isServer } from 'utils/helper'

// custom tooltip react node
const ToolTip = (props: { playersName: any }) => {
    const { playersName } = props

    return (
        <div>
            {playersName?.map(
                (
                    player: {
                        players_detail: {
                            user: { first_name: any; last_name: any }
                        }
                    },
                    index: React.Key | null | undefined,
                ) => (
                    <p key={index}>
                        {`${player?.players_detail?.user?.first_name} ${player?.players_detail?.user?.last_name}`}
                    </p>
                ),
            )}
        </div>
    )
}

const DisplayTaggedPlayers: React.FC<{ mediaId?: number | null }> = ({
    mediaId = null,
}) => {
    const { data, loading, error } = useSubscription(
        subscribe.SUB_PLAYERS_TAG,
        {
            skip: isServer || !mediaId,
            variables: {
                where: {
                    media_gallery_id: { _eq: mediaId },
                },
            },
        },
    )

    if (!mediaId) return null
    if (error) return null

    const playersList = data && data.media_gallery_players

    return (
        <Row rWidth={'50%'} gap={5}>
            <Text>{'Tag Players:'}</Text>
            {loading ? (
                <Text>Loading...</Text>
            ) : (
                <>
                    {!_.isEmpty(playersList) && (
                        <Text>
                            {playersList.length === 1
                                ? `${playersList[0]?.players_detail?.user?.first_name} ${playersList[0]?.players_detail?.user?.last_name}`
                                : _.join(
                                      [playersList[0], playersList[1]]?.map(
                                          (player) =>
                                              `${player?.players_detail?.user?.first_name} ${player?.players_detail?.user?.last_name}`,
                                      ),
                                      ', ',
                                  )}
                        </Text>
                    )}
                    {!_.isEmpty(playersList) && playersList.length > 2 && (
                        <Tooltip
                            title={ToolTip({
                                playersName: playersList.slice(
                                    2,
                                    playersList.length,
                                ),
                            })}
                            color={'black'}
                            placement="bottom"
                        >
                            <span>...+more</span>
                        </Tooltip>
                    )}
                </>
            )}
        </Row>
    )
}

export default DisplayTaggedPlayers
