import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const mediaSchema = z.object({
    clubName: z.string().optional(),
    playerName: z.string().optional(),
    matchID: z.number().optional(),
    message: z.string().optional(),
})
export type MediaSchema = z.infer<typeof mediaSchema>

export function mediaEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: MediaSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'media.upload'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: mediaSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}
