import { themeGet } from '@styled-system/theme-get'
import { Typography } from 'antd'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'
const { Text } = Typography

export interface SectionWrapperProps {
    justifyContent?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'initial'
        | 'space-around'
        | 'space-between'
        | 'stretch'
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    width?: string
}

export const MediaGalleryWrapper = styled.div``

export const ContentWrapper = styled.div`
    width: 100%;
    max-height: 500px;
    border-radius: 4px;
    margin: 2.5% 0;
    background-color: ${themeGet('colors.gray.900')};
`

export const AddMediaButtonWrapper = styled.div`
    display: flex;
    position: absolute;
    padding: 5px;
    right: 3%;
    top: 1%;
`

export const AddMediaWarningWrapper = styled.div`
    background-color: gray;
    border-radius: 5px;
`

export const SectionWrapper = styled.div<SectionWrapperProps>((props) =>
    css({
        display: 'flex',
        flexDirection: props.flexDirection ?? 'column',
        justifyContent: props.justifyContent ?? 'center',
        padding: '20px 10px 10px 10px',
        width: props.width ?? 'auto',
    }),
)

export const UploadFileWrapper = styled.div`
    margin: 0 0.5%;
`

export const UploadContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dashed 2px ${themeGet('white')};
    border-radius: 5px;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        width: 228px;
        height: 114px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 200px;
        height: 100px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 150px;
        height: 75px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        width: 100px;
        height: 50px;
    }
`

export const FileWrapper = styled.div`
    cursor: pointer;
`

export const CustomText = styled(Text)<{ mode?: string }>`
    color: white;
    text-align: center;
    font-weight: 700;

    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        font-size: 0.7rem;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        font-size: 0.5rem;
        word-wrap: break-word;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        font-size: 0.3rem;
    }

    ${({ mode }) => {
        if (mode === 'warning') {
            return `
      background-color: gray;
      padding: 5px;
      border-radius: 5px;
      `
        }
    }}
`

export const ImageHolder = styled.img`
    max-height: 114px;
    height: 114px;
`
