import { gql } from '@apollo/client'

const SUB_MEDIA_FILES = gql`
    subscription SubMediaFiles($where: media_gallery_bool_exp = {}) {
        media_gallery(where: $where) {
            id
            url
            playback_id
            match {
                league {
                    name
                }
            }
            media_gallery_clubs {
                id
                club_id
                media_gallery_id
                club {
                    id
                    name
                }
            }
            media_gallery_players {
                id
                media_gallery_id
                player_id
                players_detail {
                    id
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        }
    }
`

const SUB_CLUB_TAG = gql`
    subscription SubClubTag($where: media_gallery_club_bool_exp = {}) {
        media_gallery_club(where: $where) {
            id
            media_gallery_id
            club_id
            match_id
        }
    }
`

const SUB_PLAYERS_TAG = gql`
    subscription SubPlayersTag($where: media_gallery_players_bool_exp = {}) {
        media_gallery_players(where: $where) {
            id
            media_gallery_id
            match_id
            players_detail {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }
`

const SUB_PLAYERS = gql`
    subscription SubPlayers($where: players_details_bool_exp = {}) {
        players_details(where: $where) {
            id
            club_id
            user {
                id
                first_name
                last_name
            }
        }
    }
`

// ---------
export default {
    SUB_MEDIA_FILES,
    SUB_PLAYERS_TAG,
    SUB_CLUB_TAG,
    SUB_PLAYERS,
}
