import { useSubscription } from '@apollo/client'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { createContext, useState } from 'react'

// import styles
import { ContentWrapper } from './mediagallery.style'

// import components
import { AddMediaButton, ImageLayout, VideoLayout } from './components'
// import gql
import { subscribe } from 'graphql/mediaGallery'

// import hooks
import { useMediaGalleryAccess } from 'hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { AppState } from 'redux/store'
import { isServer } from 'utils/helper'

const MediaGalleryView: React.FC<any> = (props: {
    mode: string
    clubId: number
    playerId: number
    away_club_id: number
    home_club_id: number
}) => {
    const router = useRouter()
    const { mode, clubId, playerId, away_club_id, home_club_id } = props

    const { asset_id, player_slug } = router.query
    const club_slug = useSelector((state: RootState) => state.club.info)?.slug
    const club_admin_details = useSelector(
        (state: AppState) => state?.user?.club_admin_details,
    )

    const { mediaGalleryAccess, isAdminOfClub } = useMediaGalleryAccess(
        club_slug as string,
        clubId,
        away_club_id,
        home_club_id,
        club_admin_details,
    )
    const [images, setImages] = useState<Array<any>>([])
    const [videos, setVideos] = useState<Array<any>>([])

    let whereObject = {}

    if (asset_id && mode === 'gameday') {
        whereObject = { match_id: { _eq: asset_id } }
    } else if (club_slug && mode === 'club') {
        whereObject = {
            media_gallery_clubs: { club: { slug: { _eq: club_slug } } },
        }
    } else if (player_slug && mode === 'player') {
        whereObject = {
            media_gallery_players: {
                players_detail: { slug: { _eq: player_slug } },
            },
        }
    }

    const { data, loading } = useSubscription(subscribe.SUB_MEDIA_FILES, {
        skip: isServer,
        variables: {
            where: whereObject,
        },
        onData: ({ data: { data, loading } }) => {
            if (!data || !data?.media_gallery) return

            const imageArray = _.filter(
                data?.media_gallery,
                (file) => file.playback_id === null,
            )
            const videoArray = _.filter(
                data?.media_gallery,
                (file) => file.playback_id !== null,
            )

            setImages(imageArray)

            const formattedVideo = videoArray?.map((video) => ({
                id: video.id,
                playbackId: video.playback_id,
                backgroundImage: {
                    src:
                        'https://image.mux.com/' +
                        video.playback_id +
                        '/thumbnail.jpg?width=500',
                    height: 314,
                    width: 178,
                },
                taggedClub: video.media_gallery_clubs[0] ?? [],
                taggedPlayers: video.media_gallery_players,
                leagueName: video?.match?.league?.name,
            }))

            setVideos(formattedVideo)
        },
    })

    if (_.isEmpty(data?.media_gallery) && !mediaGalleryAccess) return <></>

    const values = {
        loading,
        mode,
        images,
        videos,
        mediaGalleryAccess,
        isAdminOfClub,
        playerId,
    }

    return (
        <MediaGalleryContext.Provider value={values}>
            <ContentWrapper>
                <AddMediaButton />
                <ImageLayout />
                <VideoLayout />
            </ContentWrapper>
        </MediaGalleryContext.Provider>
    )
}

export default MediaGalleryView
export const MediaGalleryContext = createContext<any>({})
