import { ScrollingCarousel } from 'components/ReactCarousel'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'

// import global components
import { Image } from 'components/Image'
import VideoGallery from 'components/Modal/VideoGallery'

// import styled component
import { UploadVideo } from './index'

// import styles
import { MediaBody } from 'theme/global.state'
import { FileWrapper, SectionWrapper } from '../mediagallery.style'

// import context
import { MediaGalleryContext } from '../index'

// import constants
import { VideoFileTypes } from '../constants'

// hooks
import { useRouter } from 'hooks'

export interface MediaInfoType {
    mediaId: number | null
    playbackId?: string
    clubTagName?: string
    clubTagId?: number | null
    playersTag?: any
    leagueName?: string
}

const Videos = () => {
    const { mediaGalleryAccess, mode, videos } = useContext(MediaGalleryContext)
    const { query } = useRouter()
    const [modalFlag, setModalFlag] = useState<boolean>(false)
    const [selectedMediaInfo, setSelectedMediaInfo] = useState<MediaInfoType>(
        {} as MediaInfoType,
    )

    useEffect(() => {
        // get the info of the video based on the URL query
        function hydrateSelectedImage() {
            if (_.isUndefined(query?.mg)) return

            const mediaObject = _.find(videos, [
                'id',
                parseInt(query?.mg as string),
            ])

            if (_.isUndefined(mediaObject)) return

            setModalFlag(true)
            setSelectedMediaInfo({
                mediaId: mediaObject?.id,
                playbackId: mediaObject?.playbackId,
                clubTagId: mediaObject?.taggedClub?.club_id ?? null,
                clubTagName: mediaObject?.taggedClub?.club?.name ?? null,
                playersTag: mediaObject?.taggedPlayers,
                leagueName: mediaObject?.leagueName,
            })
        }

        hydrateSelectedImage()
    }, [videos])

    const onVideoClick = (videoInfo: any) => {
        setModalFlag(true)
        setSelectedMediaInfo({
            mediaId: videoInfo.id,
            playbackId: videoInfo.playbackId,
            clubTagId: videoInfo?.taggedClub?.club_id ?? null,
            clubTagName: videoInfo?.taggedClub?.club?.name ?? null,
            playersTag: videoInfo.taggedPlayers,
            leagueName: videoInfo?.leagueName,
        })
    }

    const onCloseModal = () => {
        setModalFlag(false)
        setSelectedMediaInfo({} as MediaInfoType)
    }

    return (
        <SectionWrapper>
            <VideoGallery
                show={modalFlag}
                mode={mode}
                access={mediaGalleryAccess}
                {...selectedMediaInfo}
                handleClose={() => onCloseModal()}
            />
            <ScrollingCarousel type="Media">
                {videos.map((video: { backgroundImage: any }, index: any) => (
                    <MediaBody key={`image-key-${index}`}>
                        <FileWrapper onClick={() => onVideoClick(video)}>
                            <Image
                                width={228}
                                height={114}
                                src={video.backgroundImage}
                                alt="User uploaded content"
                            />
                        </FileWrapper>
                    </MediaBody>
                ))}

                {mediaGalleryAccess && (
                    <UploadVideo type="video" fileTypes={VideoFileTypes} />
                )}
            </ScrollingCarousel>
        </SectionWrapper>
    )
}

export default Videos
