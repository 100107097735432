import { useLazyQuery, useMutation } from '@apollo/client'
import { Button } from 'components/Button'
import { Col } from 'components/Layout'
import { Text } from 'components/Text'
import { query } from 'graphql/clips'
import { CLIPS } from 'graphql/club'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
    StyledModal,
} from './index.style'

const DeleteClipModal = (props: any) => {
    const [faveCount, setFaveCount] = useState<number | null>(null)
    const faveCountMessage = faveCount
        ? (faveCount === 1 ? '1 person has' : faveCount + ' people have') +
          ' saved this clip'
        : null

    const [deleteClip] = useMutation(CLIPS.DELETE_CLIP, {
        onCompleted() {
            props.setShow(false)
            props.closePlayerModal()
            toast.success('Clip deleted.')
            if (!props.fromClipPage) {
                props.setClips(
                    (current: any) =>
                        current?.filter((val: any) => val.id != props.assetId),
                )
            }
        },
        onError(e) {
            toast.error(`Something went wrong. Please try again later.`)
            console.log(e)
        },
    })

    const handleDelete = () => {
        props.assetId &&
            deleteClip({
                variables: {
                    where: { id: { _eq: props.assetId } },
                },
            })
    }

    const [getFaveCount] = useLazyQuery(query.CLIP_SAVE_COUNT, {
        onCompleted(data) {
            setFaveCount(data?.saved_clips_aggregate?.aggregate?.count)
        },
        fetchPolicy: 'no-cache',
    })

    useEffect(
        () => {
            if (props.show) {
                getFaveCount({
                    variables: {
                        where: {
                            clip_id: {
                                _eq: props.assetId,
                            },
                        },
                    },
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.show],
    )

    return (
        <StyledModal show={props.show}>
            <ModalContent>
                <ModalHeader>
                    <Col>
                        <Text fSize={1.5} fWeight={700} tAlign={'center'}>
                            Delete Clip
                        </Text>
                        <Text
                            fSize={1}
                            fWeight={200}
                            tAlign={'center'}
                            style={`
                                color: rgb(153 152 153);
                            `}
                        >
                            Are you sure you want to delete this clip?
                        </Text>

                        {faveCount ? (
                            <Text fWeight={600} fSize={1} tAlign="center">
                                {faveCountMessage}
                            </Text>
                        ) : null}
                    </Col>
                </ModalHeader>
                <ModalFooter>
                    <Button
                        onClick={() => props.setShow(false)}
                        style={`
                            border: 0;
                            background-color: rgb(87 86 87);
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDelete()}
                        variant="warning"
                        style={`
                            margin-left: 20px;
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </StyledModal>
    )
}

export default DeleteClipModal
