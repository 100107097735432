import { useMutation } from '@apollo/client'
import * as UpChunk from '@mux/upchunk'
import { UploadImageIcon, UploadVideoIcon } from 'assets/icon'
import { mutate } from 'graphql/mediaGallery'
import { useInsertActivityFeed, useUser } from 'hooks'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import useSwr from 'swr'
import { createUploadURL } from '../helper'
import { MediaGalleryContext } from '../index'
import {
    CustomText,
    UploadContentWrapper,
    UploadFileWrapper,
} from '../mediagallery.style'

const fetcher = (url: any) => {
    return fetch(url).then((res) => res.json())
}

const UploadVideo = (props: any) => {
    const { mode, playerId } = useContext(MediaGalleryContext)
    const { clubInfo, type, fileTypes } = props
    const [isUploading, setIsUploading] = useState(false)
    const [isPreparing, setIsPreparing] = useState(false)
    const [progress, setProgress] = useState(0)
    const [uploadId, setUploadId] = useState(null)
    const { user }: { user: any } = useUser()
    const router = useRouter()
    const { asset_id } = router.query

    const [inserMediaFile] = useMutation(mutate.ADD_MEDIA_FILE, {
        onCompleted() {
            toast.success(`Added ${type} to the media gallery`)
        },
        onError(e) {
            toast.error(`Error while adding ${type} to the media gallery` + e)
        },
    })

    const insertFeed = useInsertActivityFeed()

    const [addTagClub] = useMutation(mutate.ADD_CLUB_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const [addTagPlayer] = useMutation(mutate.ADD_PLAYER_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const { data } = useSwr(
        () => (isPreparing ? `/api/mediaGallery/${uploadId}` : null),
        fetcher,
        { refreshInterval: 5000 },
    )

    const upload = data && data.upload

    useEffect(() => {
        async function insertVideo() {
            if (!upload && !upload?.asset_id) return

            const mediaFileObject = {
                club_id: clubInfo.id,
                match_id: asset_id,
                playback_id: upload.playback_id,
                asset_id: upload.asset_id,
                url: upload.asset_id,
                upload_id: upload.upload_id,
                added_by: user?.id,
            }

            const { data } = await inserMediaFile({
                variables: {
                    objects: mediaFileObject,
                },
            })

            // insert activity feed
            if (!_.isEmpty(user?.players_details)) {
                const feedInput = {
                    player_id: user?.players_details[0]?.id ?? null,
                    type: 'media_gallery',
                    ref_id: data.insert_media_gallery.returning[0].id,
                    action: 'upload',
                }

                await insertFeed([feedInput])
            }

            // tag player
            if (mode === 'player' && playerId) {
                const mediaGalleryInfo =
                    data.insert_media_gallery.returning[0] ?? null

                const mediaTagPlayerObj = {
                    player_id: playerId,
                    media_gallery_id: mediaGalleryInfo?.id,
                }

                const { data: playerTag } = await addTagPlayer({
                    variables: {
                        objects: mediaTagPlayerObj,
                    },
                })

                const mediaPlayerTagId =
                    playerTag.insert_media_gallery_players.returning[0].id

                // insert activity feed for tagging player
                const feedInput = {
                    player_id: playerId,
                    type: 'media_gallery',
                    ref_id: mediaPlayerTagId,
                    action: 'tag',
                }

                await insertFeed([feedInput])
            }

            // tag club
            if (mode === 'club' || mode === 'player') {
                const mediaGalleryInfo =
                    data.insert_media_gallery.returning[0] ?? null

                const mediaTagClubObj = {
                    club_id: clubInfo.id,
                    media_gallery_id: mediaGalleryInfo?.id,
                }

                await addTagClub({
                    variables: {
                        objects: mediaTagClubObj,
                    },
                })
            }

            setIsPreparing(false)
        }

        insertVideo()
    }, [upload])

    const onFinish = async (file: File) => {
        //10MB = 10000KB = 10000000 bytes
        if (file.size > 10000000) {
            toast.warning('File exceeded the maximum size of 10mb')
            return
        }

        const data = await createUploadURL()

        setUploadId(data.id)
        setIsUploading(true)
        const upload = UpChunk.createUpload({
            endpoint: data.url,
            file: file,
        })

        upload.on('error', (e) => {
            toast.error(`Error while adding ${type} to the media gallery` + e)
            setIsUploading(false)
        })

        upload.on('progress', (progress) => {
            setProgress(Math.floor(progress.detail))
        })

        upload.on('success', () => {
            setIsPreparing(true)
            setIsUploading(false)
        })
    }

    return (
        <UploadFileWrapper>
            <FileUploader
                handleChange={(file: File) => onFinish(file)}
                name={type}
                types={fileTypes}
                disabled={isUploading}
            >
                <UploadContentWrapper>
                    {type === 'image' ? (
                        <UploadImageIcon iColor="white" />
                    ) : (
                        <UploadVideoIcon iColor="white" />
                    )}
                    <CustomText>
                        {isUploading
                            ? `Currently uploading ${type}... ${progress}%`
                            : 'Upload or drag a file right here'}
                    </CustomText>

                    <CustomText>
                        {!isUploading && fileTypes.toString()}
                    </CustomText>
                </UploadContentWrapper>
            </FileUploader>
        </UploadFileWrapper>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(UploadVideo)
