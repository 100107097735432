import { connect } from 'react-redux'
// import styled
import PageBanner from 'components/Banner'
const Banner = (props: { banner: any }) => {
    const { banner } = props

    return <PageBanner image={banner} />
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(Banner)
