import { ScrollerGeneralType } from 'components/ReactCarousel'
import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import {
    ScrollerLoadEventType,
    ScrollerScrollEventType,
} from '../gtm/event-types'
import { raiseEvent } from '../gtm/gtm'

const scrollSchema = z.object({
    offset: z.number(),
})

export type ScrollSchema = z.infer<typeof scrollSchema>

export function scrollerScrollEvent({
    type,
    offset,
    page = 'Home',
    gtm,
    datadog,
}: {
    type: ScrollerGeneralType
    offset: number
    page: string
    gtm?: boolean
    datadog?: boolean
}) {
    let eventName: ScrollerScrollEventType
    switch (type) {
        case 'Clip':
            eventName = 'scroller.clipScroll'
            break
        case 'Replay':
            eventName = 'scroller.replayScroll'
            break
        case 'Live':
            eventName = 'scroller.liveScroll'
            break
        case 'League':
            eventName = 'scroller.leagueScroll'
            break
        case 'Club':
            eventName = 'scroller.clubScroll'
            break
        case 'Team':
            eventName = 'scroller.teamScroll'
            break
        case 'Avatar':
            eventName = 'scroller.avatarScroll'
            break
        case 'Media':
            eventName = 'scroller.mediaScroll'
            break
        default:
            console.error(
                `Invalid type '${type}' used for scroller scroll event.`,
            )
            return
    }

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: { offset: offset },
            originator: page,
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, {
            offset: offset,
        })
    }
}

export function scrollerLoadContentEvent({
    type,
    offset,
    page = 'Home',
    gtm,
    datadog,
}: {
    type: ScrollerGeneralType
    offset: number
    page: string
    gtm?: boolean
    datadog?: boolean
}) {
    let eventName: ScrollerLoadEventType
    switch (type) {
        case 'Replay':
            eventName = 'scroller.replayLoad'
            break
        case 'Clip':
            eventName = 'scroller.clipLoad'
            break
        case 'Live':
            eventName = 'scroller.liveLoad'
            break
        default:
            console.error(
                `Invalid type '${type}' used for scroller load content event.`,
            )
            return
    }

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: { offset: offset },
            originator: page,
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, {
            offset: offset,
        })
    }
}
