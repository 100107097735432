import { themeGet } from '@styled-system/theme-get'
import { Row } from 'components/Layout'
import styled from 'styled-components'

export const GameDayWrapper = styled.div`
    margin: 15px 0;
`
export const LinkWrapper = styled.div`
    cursor: pointer;
    color: ${themeGet('colors.gray.200')};
    :hover {
        text-decoration: underline;
        color: ${themeGet('colors.white')};
    }
`
export const SeeAllWrapper = styled(Row)`
    font-size: 14px;
`
