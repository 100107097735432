import { useLazyQuery, useMutation } from '@apollo/client'
import { Popover } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FeatureClipsDownloadButton } from 'components/Button/index'
import { Row } from 'components/Layout'
import { SocialShare } from 'components/SocialShare'
import { Text } from 'components/Text'
import { query } from 'graphql/clips'
import { USER_SAVE_CLIP, USER_UNSAVE_CLIP } from 'graphql/clips/mutatations'
import { useRouter, useUser } from 'hooks'
import { useAdminAccess } from 'hooks/useAdminAccess'
import useMediaQuery from 'hooks/useMediaQuery'
import { clipSaveEvent } from 'lib/tracking/events/clip'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { ClubContext } from 'pages/club/[club_slug]'
import { PlayerContext } from 'pages/club/[club_slug]/player/[player_slug]'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { AiFillEdit, AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { FiPlay, FiShare2, FiTrash, FiX } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { ClipAssetProps } from 'types/components/Modal'
import { getStreamPagePath } from 'utils/common-helper'
import { baseUrl } from 'utils/constData'
import { datetimeToLocal } from 'utils/helper-date'
import DeleteClipModal from '../DeleteClipModal'
import EditClipNameModal from '../EditClipNameModal'
import {
    ButtonsWrapper,
    ClubLinkWrapper,
    EditClipNameButton,
    FeatureClipButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
    TitleWrapper,
} from './feature-clip.style'

import { clipShareEvent } from 'lib/tracking/events/clip'

const VideoPlayer = dynamic(() => import('components/Video/Bitmovin'), {
    ssr: false,
})

const clubLinkText = (slug: string | undefined, label: string | undefined) => {
    return slug ? (
        <Link href={`/club/${slug}`} legacyBehavior>
            {label}
        </Link>
    ) : (
        <p>{label}</p>
    )
}

export const FeatureClip_Modal: React.FC<ClipAssetProps> = ({
    clubId,
    matchId,
    eventStreamsId,
    eventStreamsSlug,
    title,
    homeClubName,
    awayClubName,
    awayClubDisplayName,
    homeClubDisplayName,
    matchDateTime,
    submittedDateTime,
    homeClubSlug,
    awayClubSlug,
    submittedBy,
    playbackId,
    assetId,
    desc,
    show = false,
    handleClose,
    staticFileStatus,
    id,
    setClips,
    clubInfo,
    leagueName,
    leagueSlug,
    author_id,
}) => {
    const { path, router }: any = useRouter()
    const [form] = useForm()
    const { isClubAdmin, isLeagueAdmin, user } = useUser()
    const club = useContext(ClubContext)
    const player = useContext(PlayerContext)
    const [showDelModal, setShowDelModal] = useState(false)
    const [clipTitle, setClipTitle] = useState(title)
    const [showEditClipModal, setShowEditClipModal] = useState(false)
    const isClipAuthor: boolean =
        !!author_id && !!user?.id && author_id === user?.id
    const { showClubAdminLink: isHomeAdmin } = useAdminAccess(
        leagueSlug,
        homeClubSlug,
    )
    const { showClubAdminLink: isAwayAdmin, showLeagueAdminLink } =
        useAdminAccess(leagueSlug, awayClubSlug)

    const isClipAdmin =
        isClipAuthor ||
        isClubAdmin ||
        isLeagueAdmin ||
        showLeagueAdminLink ||
        isHomeAdmin ||
        isAwayAdmin

    const [saved, setSaved] = useState(false)
    const isSmallMobile = useMediaQuery(600)

    const showStreamLink =
        !path.includes('/match/') &&
        !path.includes('/event/') &&
        (matchId || (eventStreamsId && eventStreamsSlug))

    const streamLink = matchId
        ? getStreamPagePath({
              matchId: matchId ?? -1,
          })
        : getStreamPagePath({
              eventId: eventStreamsId ?? -1,
              eventSlug: eventStreamsSlug ?? '',
          })

    const [getSavedStatus, { loading }] = useLazyQuery(query.USER_SAVED_CLIP, {
        onCompleted(data) {
            setSaved(data?.saved_clips.length > 0)
        },
        fetchPolicy: 'no-cache',
    })
    useEffect(() => {
        if (!show || !id) return
        getSavedStatus({
            variables: {
                where: {
                    user_id: { _eq: user?.id ?? -1 },
                    clip_id: {
                        _eq: id,
                    },
                },
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, id])

    const pauseVideo = () => {
        const videoContainers = document.querySelectorAll(
            '#bitmovinplayer-video-player-container',
        )
        if (videoContainers.length >= 2) {
            const secondVideoContainer = videoContainers[1] as HTMLVideoElement
            secondVideoContainer.pause()
        } else {
            const video = document.getElementById(
                'bitmovinplayer-video-player-container',
            ) as HTMLVideoElement | undefined
            video?.pause()
        }
    }

    const [
        saveFeaturedClip,
        { error: saveError, data: saveData, loading: saveLoading },
    ] = useMutation(USER_SAVE_CLIP, {
        onCompleted() {
            setSaved(true)
        },
        onError(e: any) {
            toast.error('An error occurred, try again in a moment.')
        },
    })

    const [
        unsaveFeaturedClip,
        { error: unsaveError, data: unsaveData, loading: unsaveLoading },
    ] = useMutation(USER_UNSAVE_CLIP, {
        onCompleted() {
            setSaved(false)
        },
        onError(e: any) {
            toast.error('An error occurred, try again in a moment.')
        },
    })

    const goToGame = () => {
        router.push(streamLink)
    }

    const toggleSaveClip = async () => {
        clipSaveEvent({
            props: {
                league: `${leagueName}`,
                clip_name: `${title}`,
                clip_id: id ?? 0,
                mode: saved ? 'unsave' : 'save',
                user: !!user,
            },
        })

        if (!user) {
            toast.warning('Sign in to save clips!')
            return
        }

        if (saved) {
            await unsaveFeaturedClip({
                variables: {
                    where: {
                        user_id: { _eq: user.id },
                        clip_id: { _eq: id },
                    },
                },
            })
        } else {
            await saveFeaturedClip({
                variables: {
                    objects: {
                        user_id: user.id,
                        clip_id: id,
                    },
                },
            })
        }
    }

    const handleDeleteClip = useCallback(() => {
        pauseVideo()
        setShowDelModal(true)
    }, [setShowDelModal])

    const trackShareClip = () => {
        router.pathname === `/` &&
            clipShareEvent({
                props: {
                    clipName: clipTitle,
                    message: 'Share Clip (Home Page)',
                },
            })
        router.pathname === `/league/[league_slug]` &&
            clipShareEvent({
                props: {
                    clipName: clipTitle,
                    message: 'Share Clip (League Page)',
                },
            })

        router.pathname === `/club/[club_slug]` &&
            clipShareEvent({
                props: {
                    clipName: clipTitle,
                    clubName: club?.name,
                    message: 'Share Clip (Club Page)',
                },
            })
        router.pathname === `/club/[club_slug]/player/[player_slug]` &&
            clipShareEvent({
                props: {
                    clipName: clipTitle,
                    playerName: `${player?.player?.user?.first_name} ${player?.player?.user?.last_name}`,
                    message: 'Share Clip (Player Profile Page)',
                },
            })
    }

    const handleEditClipName = useCallback(() => {
        pauseVideo()
        setShowEditClipModal(true)
    }, [setShowEditClipModal])

    const handleSuccessEditName = useCallback(
        (clipID: number) => {
            setShowEditClipModal(false)
            toast.success('Clip name updated')
            setClipTitle(form.getFieldValue('name'))
            setTimeout(() => {
                setClips &&
                    setClips((currentList: any) => {
                        const currentList1 = currentList
                        const updateIndex = currentList1.findIndex(
                            (obj: any) => obj.id === clipID,
                        )
                        currentList1[updateIndex].title =
                            form.getFieldValue('name')

                        return currentList1
                    })
            }, 500)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setShowEditClipModal, setClipTitle],
    )

    useEffect(() => {
        setClipTitle(title)
    }, [title])

    const escapeFunction = useCallback((event: any) => {
        if (event.key === 'Escape') {
            handleClose && handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (show) {
            document.addEventListener('keydown', escapeFunction, false)
        }

        return () => {
            document.removeEventListener('keydown', escapeFunction, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const datetime = useMemo(() => {
        return submittedDateTime
            ? `on ${datetimeToLocal(submittedDateTime, {
                  hideTime: true,
                  hideTimezone: true,
              })}`
            : ''
    }, [submittedDateTime])

    const streamLinkContent = (
        <Text fSize={1} mode="p" fWeight={500} tSpacing={-0.2} lHeight={16}>
            {desc}
        </Text>
    )

    return (
        <>
            <ModalWrapper role="dialog" show={show} onClick={handleClose}>
                <ModalContent
                    show={show}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <ModalHeader>
                        <button type="button" onClick={handleClose}>
                            <FiX size={28} color="gray" />
                        </button>
                    </ModalHeader>
                    <ModalBody>
                        <Row flexDirection="column" gap={10}>
                            <VideoPlayer
                                key={playbackId}
                                home_name={homeClubName}
                                away_name={awayClubName}
                                playback_id={playbackId}
                                video_title={clipTitle ?? title}
                                league_name={leagueName}
                                video_type={'Clip'}
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <TitleWrapper>
                            {submittedBy && (
                                <Text
                                    className="footer-text-details"
                                    lHeight={14}
                                >
                                    Created by:{' '}
                                    <span className="footer-createdby">
                                        {' ' + submittedBy + ' '}
                                    </span>
                                    <span className="footer-text-details">
                                        {datetime}
                                    </span>
                                </Text>
                            )}
                            <Row alignItems="center" gap={8}>
                                <Text
                                    fSize={1.5}
                                    fWeight={800}
                                    fColor="white"
                                    mode="h1"
                                >
                                    {clipTitle}
                                </Text>
                                {isClipAdmin && (
                                    <EditClipNameButton
                                        onClick={handleEditClipName}
                                        bColor="primary"
                                        bSize="small"
                                        icon={<AiFillEdit size={20} />}
                                        className="actionBtn"
                                    />
                                )}
                            </Row>

                            {showStreamLink ? (
                                <Link href={streamLink}>
                                    {streamLinkContent}
                                </Link>
                            ) : (
                                <>{streamLinkContent}</>
                            )}

                            <ClubLinkWrapper>
                                {!awayClubDisplayName ||
                                homeClubDisplayName === awayClubDisplayName ? (
                                    clubLinkText(
                                        homeClubSlug,
                                        homeClubDisplayName,
                                    )
                                ) : (
                                    <div>
                                        {clubLinkText(
                                            homeClubSlug,
                                            homeClubDisplayName,
                                        )}
                                        <span> vs </span>
                                        {clubLinkText(
                                            awayClubSlug,
                                            awayClubDisplayName,
                                        )}
                                    </div>
                                )}
                            </ClubLinkWrapper>
                        </TitleWrapper>

                        <ButtonsWrapper>
                            {isClipAdmin && (
                                <FeatureClipsDownloadButton
                                    playbackId={playbackId ?? ''}
                                    league={leagueName ?? ''}
                                    clipId={id ?? 0}
                                    clipName={title ?? ''}
                                />
                            )}
                            {showStreamLink && (
                                <FeatureClipButton
                                    bColor="primary"
                                    bSize="small"
                                    icon={<FiPlay />}
                                    className="actionBtn"
                                    onClick={goToGame}
                                >
                                    {`${
                                        isSmallMobile ? '' : 'Watch '
                                    }Full Stream`}
                                </FeatureClipButton>
                            )}
                            <Popover
                                content={
                                    <SocialShare
                                        url={`${baseUrl}${`/clips/`}${id}`}
                                    />
                                }
                                trigger="click"
                            >
                                <FeatureClipButton
                                    bColor="primary"
                                    bSize="small"
                                    icon={<FiShare2 />}
                                    className="actionBtn"
                                    onClick={trackShareClip}
                                >
                                    Share
                                </FeatureClipButton>
                            </Popover>
                            {isClipAdmin && (
                                <FeatureClipButton
                                    bSize="small"
                                    icon={<FiTrash color="red" />}
                                    className="actionBtn"
                                    onClick={handleDeleteClip}
                                >
                                    <Text fColor="red.regular">Delete</Text>
                                </FeatureClipButton>
                            )}
                            {
                                <FeatureClipButton
                                    onClick={toggleSaveClip}
                                    disabled={
                                        loading || saveLoading || unsaveLoading
                                    }
                                    icon={
                                        saved ? (
                                            <AiFillStar size={24} />
                                        ) : (
                                            <AiOutlineStar size={24} />
                                        )
                                    }
                                >
                                    {saved ? <p>Saved</p> : <p>Save</p>}
                                </FeatureClipButton>
                            }
                        </ButtonsWrapper>
                    </ModalFooter>
                </ModalContent>
            </ModalWrapper>
            <DeleteClipModal
                show={showDelModal}
                setShow={setShowDelModal}
                setClips={setClips}
                assetId={id}
                closePlayerModal={handleClose}
            />
            <EditClipNameModal
                show={showEditClipModal}
                setShow={setShowEditClipModal}
                title={clipTitle}
                clipID={id}
                setClips={setClips}
                form={form}
                handleSuccessEditName={handleSuccessEditName}
            />
        </>
    )
}
