import React, { useContext, useState } from 'react'
import { FiShare2 } from 'react-icons/fi'
import { useRouter } from 'next/router'
import { Select, Popover } from 'antd'
const { Option } = Select

// styles
import {
    ModalWrapper,
    ModalContent,
    ModalHeader,
    ModalBody,
    ImageHolder,
    ModalFooter,
} from './index.style'
// components
import { Button } from 'components/Button'
import { TaggingLayout } from 'sections/mediagallery/components'
import { SocialShare } from 'components/SocialShare'
// utils
import { ImCancelCircle } from 'react-icons/im'
import { baseUrl } from 'utils/constData'

// types
import { ImageModalProps } from 'types/components/Modal'
import DeleteMediaModal from '../DeleteMedia'
import { MediaGalleryContext } from 'sections/mediagallery'

const ImageModal: React.FC<ImageModalProps> = ({
    show = false,
    handleClose,
    mediaSrc,
    mediaId,
    clubTagId,
    clubTagName,
    playersTag,
}) => {
    const router = useRouter()
    const { isAdminOfClub } = useContext(MediaGalleryContext)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const handleDeleteClick = () => {
        setShowDeleteModal(true)
    }
    return (
        <ModalWrapper show={show}>
            <ModalContent show={show}>
                <ModalHeader>
                    <Button
                        style={{
                            backgroundColor: '#1B1B25',
                        }}
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                    />
                </ModalHeader>
                <ModalBody>
                    {mediaSrc && <ImageHolder src={mediaSrc} />}
                </ModalBody>
                <ModalFooter>
                    <TaggingLayout
                        mediaId={mediaId}
                        clubTagId={clubTagId!}
                        clubTagName={clubTagName!}
                        playersTag={playersTag}
                        modalShown={show}
                    />
                    {isAdminOfClub && (
                        <Button
                            bColor="warning"
                            bSize="small"
                            icon={<ImCancelCircle />}
                            type="button"
                            key={'btn-del'}
                            onClick={handleDeleteClick}
                        >
                            {'Delete'}
                        </Button>
                    )}
                    <Popover
                        content={
                            <SocialShare
                                url={`${baseUrl}${
                                    router.asPath.split('?')[0] === '/'
                                        ? `/media-gallery/`
                                        : router.asPath.split('?')[0] +
                                          `/media-gallery/`
                                }${mediaId}`}
                            />
                        }
                        trigger={'click'}
                    >
                        <Button
                            bColor="primary"
                            bSize="small"
                            icon={<FiShare2 />}
                            className="actionBtn"
                        >
                            {'Share'}
                        </Button>
                    </Popover>
                </ModalFooter>
            </ModalContent>
            <DeleteMediaModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                mediaID={mediaId}
                setMediaModalShow={handleClose}
            />
        </ModalWrapper>
    )
}

export default ImageModal
