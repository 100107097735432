import themeGet from '@styled-system/theme-get'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const BannerSpacer = styled.article`
    width: 100vw;
    height: 38vmin;
    position: relative;

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 32vmin;
    }
`

export const BannerImageWrapper = styled.div`
    width: 100vw;
    height: 43vmin;
    margin-bottom: 2vmin;
    position: relative;
`

export const BannerFade = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
        0deg,
        ${themeGet('colors.black.200')} 0%,
        transparent 35%
    );

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        background: linear-gradient(
            0deg,
            ${themeGet('colors.black.200')} 0%,
            transparent 55%
        );
    }
`
