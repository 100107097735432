import React, { useContext, useState, createContext, useEffect } from 'react'
import { PencilIcon, SaveIcon } from '@heroicons/react/outline'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useRouter } from 'next/router'

// components
import { TagClub, TagPlayers } from 'sections/mediagallery/components'
import { Button } from 'components/Button'
import { Row } from 'components/Layout'
import DisplayTaggedClub from './DisplayTaggedClub'

// gql
import { mutate } from 'graphql/mediaGallery'
import { mutate as actFeedMutate } from 'graphql/player'
// context
import { MediaGalleryContext } from '../index'

// hooks
import { useInsertActivityFeed } from 'hooks'

const TaggingLayout: React.FC<{
    mediaId: number | null
    clubTagId: number | null
    clubTagName: string | null
    playersTag?: any
    modalShown?: boolean
}> = ({
    mediaId,
    clubTagId = null,
    playersTag,
    clubTagName,
    modalShown = false,
}) => {
    const { mediaGalleryAccess, mode } = useContext(MediaGalleryContext)
    const [taggedPlayersInfo, setTaggedPlayersInfo] = useState<any>(null)
    const [selectedClubId, setSelectedClubId] = useState<number | null>(null)
    const [selectedPlayers, setSelectedPlayers] = useState<
        Array<number | null>
    >([])
    const [deSelectedPlayers, setDeSelectedPlayers] = useState<
        Array<number | null>
    >([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const router = useRouter()
    const { asset_id } = router.query

    // clean up states after closing the media file modal.
    useEffect(() => {
        if (modalShown) return

        setSelectedClubId(null)
        setSelectedPlayers([])
        setDeSelectedPlayers([])
    }, [modalShown])

    // hydrate initial selected club tag
    useEffect(() => {
        setSelectedClubId(clubTagId)
        setEditMode(!clubTagId)
    }, [clubTagId, modalShown])

    // hydrate initial tagged players info
    useEffect(() => {
        function getTaggedPlayersInfo() {
            if (!playersTag || !modalShown) return

            setTaggedPlayersInfo(playersTag)
        }

        getTaggedPlayersInfo()
    }, [playersTag, modalShown])

    // hydrate initial selected values for the players dropdown element
    useEffect(() => {
        function setTaggedPlayers() {
            if (!taggedPlayersInfo || !modalShown) return

            setSelectedPlayers(
                _.map(taggedPlayersInfo, (player) => player.player_id),
            )
        }

        setTaggedPlayers()
    }, [taggedPlayersInfo, modalShown])

    const insertFeed = useInsertActivityFeed()

    const [upsertPlayerTag] = useMutation(mutate.UPSERT_PLAYER_TAG, {
        onError(e) {
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const [upsertMediaGalleryClub] = useMutation(mutate.UPSERT_CLUB_TAG, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const [deletePlayerTag] = useMutation(mutate.DELETE_PLAYER_TAG, {
        onError(e) {
            console.log('error::delete:', e)
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const [deleteActFeed] = useMutation(actFeedMutate.DELETE_ACTIVITY_FEED, {
        onError(e) {
            console.log('error::delete:', e)
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const handleOnSubmit = async () => {
        try {
            await handleUpsertClub()
            await handleTagPlayer()
            await handleRemovePlayerTag()
        } catch (e) {
            toast.error(`Something went wrong. Please try again later.`)
        } finally {
            setEditMode(false)
        }
    }

    const handleUpsertClub = async () => {
        if (mode !== 'gameday') return

        try {
            const mediaGalleryClubObj = {
                club_id: selectedClubId,
                media_gallery_id: mediaId,
                match_id: asset_id,
            }

            const { data } = await upsertMediaGalleryClub({
                variables: {
                    objects: mediaGalleryClubObj,
                    update_columns: Object.keys(mediaGalleryClubObj),
                },
            })

            const result = data.insert_media_gallery_club.returning[0] ?? null

            if (!result) {
                toast.error(`Something went wrong. Please try again later.`)

                return
            }
        } catch (e) {
            toast.error(`Something went wrong. Please try again later.`)
        }
    }

    const handleTagPlayer = async () => {
        if (_.isEmpty(selectedPlayers)) return

        try {
            const playerTagObj = {
                media_gallery_id: mediaId,
                match_id: asset_id,
            }

            const playerTagArray = _.map(selectedPlayers, (player_id) => ({
                ...playerTagObj,
                player_id: player_id,
                id: _.find(
                    taggedPlayersInfo,
                    (player) => player.player_id === player_id,
                )?.id,
            }))

            const { data: playersData } = await upsertPlayerTag({
                variables: {
                    objects: playerTagArray,
                    update_columns: Object.keys(playerTagObj),
                },
            })

            const playerResult =
                playersData.insert_media_gallery_players.returning ?? null

            if (!playerResult) return

            const feedInput = _.map(playerResult, (playerTag) => ({
                player_id: playerTag.player_id,
                type: 'media_gallery',
                ref_id: playerTag.id,
                action: 'tag',
            }))

            const feedResponse = await insertFeed(feedInput)

            if (!playerResult || !feedResponse) {
                toast.error(`Something went wrong. Please try again later.`)
            }

            setTaggedPlayersInfo(playerResult)
        } catch (e) {
            toast.error(`Something went wrong. Please try again later.`)
        }
    }

    const handleRemovePlayerTag = async () => {
        if (_.isEmpty(deSelectedPlayers)) return

        try {
            const tagRemovedRes = await Promise.all(
                deSelectedPlayers.map(async (player_id) => {
                    return deletePlayerTag({
                        variables: {
                            where: {
                                player_id: { _eq: player_id },
                                media_gallery_id: { _eq: mediaId },
                            },
                        },
                    })
                        .then((response) => ({
                            ref_id: response.data.delete_media_gallery_players
                                .returning[0].id,
                            player_id:
                                response.data.delete_media_gallery_players
                                    .returning[0].player_id,
                        }))
                        .catch((er) => {
                            toast.error(
                                `Something went wrong. Please try again later.`,
                            )
                        })
                }),
            )

            await Promise.all(
                tagRemovedRes.map(async (tagData) => {
                    await deleteActFeed({
                        variables: {
                            where: {
                                player_id: { _eq: tagData?.player_id },
                                ref_id: { _eq: tagData?.ref_id },
                                type: { _eq: 'media_gallery' },
                            },
                        },
                    })
                }),
            )
        } catch (e) {
            toast.error(`Something went wrong. Please try again later.`)
        } finally {
            setDeSelectedPlayers([])
        }
    }

    const value = {
        editMode,
        mediaId,
        selectedClubId,
        setSelectedClubId,
        taggedPlayersInfo,
        selectedPlayers,
        setSelectedPlayers,
        deSelectedPlayers,
        setDeSelectedPlayers,
    }

    return (
        <TaggingFilesContext.Provider value={value}>
            <Row gap={5} justifyContent="space-between">
                {mode === 'gameday' && mediaGalleryAccess ? (
                    <TagClub />
                ) : (
                    <DisplayTaggedClub clubTagName={clubTagName} />
                )}
                <TagPlayers />
                {editMode ? (
                    <Button
                        disabled={_.isNull(selectedClubId)}
                        onClick={handleOnSubmit}
                    >
                        <SaveIcon
                            aria-hidden="true"
                            style={{
                                height: '20px',
                                width: '20px',
                                color: 'white',
                            }}
                        />
                    </Button>
                ) : (
                    <Button onClick={() => setEditMode(true)}>
                        <PencilIcon
                            aria-hidden="true"
                            style={{
                                height: '15px',
                                width: '15px',
                                color: 'white',
                            }}
                        />
                    </Button>
                )}
            </Row>
        </TaggingFilesContext.Provider>
    )
}

export default TaggingLayout
export const TaggingFilesContext = createContext<any>({})
