import React from 'react'

// global component
import { Row } from 'components/Layout'
import { Text } from 'components/Text'

const DisplayTaggedClub: React.FC<{ clubTagName?: string | null }> = ({
    clubTagName,
}) => {
    return (
        <Row rWidth={'50%'} gap={5}>
            <Text>{'Tag Club:'}</Text>
            <Text>{clubTagName}</Text>
        </Row>
    )
}

export default DisplayTaggedClub
