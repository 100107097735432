import { Row } from 'components/Layout'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const StyledModal = styled.div<{ show: boolean }>`
    position: fixed;
    display: flex;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalContent = styled.div`
    border-radius: 20px;
    border: 2px solid rgb(80 80 80);
    background-color: rgb(90 89 90);
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 22%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 38%;
    max-width: 444px;
    padding: 1.5rem;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 50%;
        height: 29%;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 80%;
        height: 30%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 2;
    width: 100%;
`

export const ModalFooter = styled(Row)`
    justify-content: right;
    align-items: flex-start;
    flex: 0.5;
    transition: 0;
`
