import {
    EventCardProps,
    GameCardProps,
    MatchGameClubs,
} from 'types/components/GameCard'
import { getStreamPagePath } from 'utils/common-helper'

export function isEvent(obj: any): obj is EventCardProps {
    if (obj && typeof obj === 'object') {
        return obj.mode === 'Event' || obj.is_event
    }
    return false
}

function useMatchUrl(
    mode: 'Replay' | 'Match' | 'Day' | 'Clip' | 'Live' | 'Event',
    match: { matchClubs: MatchGameClubs } | (GameCardProps & EventCardProps),
) {
    if (!match) {
        return ''
    }

    if (isEvent(match)) {
        return getStreamPagePath({
            eventId: match.id,
            eventSlug: match.event_slug ?? '',
        })
    }

    if (['Clip'].includes(mode)) {
        return ''
    }

    return getStreamPagePath({ matchId: match.matchClubs.match_id })
}

export default useMatchUrl
