import { ScrollerGeneralType } from 'components/ReactCarousel'
import _ from 'lodash'
import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { CardBody } from 'theme/global.state'
import { AvatarSkeleton } from '..'
import MatchSkeleton from '../LiveMatch'

interface Props {
    mode: ScrollerGeneralType
    cards?: number
    onChange?: (isVisible: boolean) => void
    active?: boolean
    offset?: number
}

const LoadMore: React.FC<Props> = ({
    mode = 'Match',
    cards = 6,
    onChange,
    active = true,
    offset = 0,
}) => {
    function renderSkeleton() {
        switch (mode) {
            case 'Avatar':
                return <AvatarSkeleton />
            case 'Live':
            case 'Replay':
            case 'Clip':
                return <MatchSkeleton mode={mode} />
            default:
                return null
        }
    }

    // console.log(mode)
    return (
        <VisibilitySensor active={true} delayedCall={true} onChange={onChange}>
            <>
                {_.range(cards).map((item: number) => {
                    return (
                        <CardBody key={`load-more-skeletor${item}`}>
                            {renderSkeleton()}
                        </CardBody>
                    )
                })}
            </>
        </VisibilitySensor>
    )
}

export default LoadMore
