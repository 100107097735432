import { useSubscription } from '@apollo/client'
import ThumbnailCard from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { SeeAll } from 'components/Scroller/SeeAll'
import { Text } from 'components/Text'
import subscribe from 'graphql/match/subscriptions'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { GameCardProps } from 'types/components/GameCard'
import { getStreamPagePath, thumbNailLink } from 'utils/common-helper'
import { isServer } from 'utils/helper'
import { ReplayWrapper } from './replay.style'

/** TODO: Fix Typo Reply to Replay */
const RecentGamesView: React.FC = (props: any) => {
    const router = useRouter()
    const { club_slug, team_slug } = router.query
    const { playerDetailId, initialData } = props
    const ssrData = initialData?.initialData?.recent
    const [matches, setMatches] = useState<null | Array<any>>(ssrData)

    useSubscription(subscribe.SUB_MATCHES, {
        skip: isServer || !playerDetailId,
        variables: {
            where: {
                stream_info: {
                    status: { _eq: 'completed' },
                    archived: { _neq: true },
                },
                home_team: {
                    players: {
                        id: { _eq: playerDetailId },
                    },
                },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setMatches(data.matches)
        },
    })

    const onHandleClick = (id: number) => {
        router.push(
            getStreamPagePath({
                matchId: id,
            }),
        )
    }

    if (!matches) {
        return <></>
    }

    return (
        <ReplayWrapper>
            <Row alignItems="center">
                <Col item={24}>
                    <Row>
                        <Text
                            fColor="white"
                            fSize={1.375}
                            fWeight={700}
                            mode="h2"
                        >
                            {'Recent Games'}
                        </Text>
                    </Row>
                </Col>
                <Col item={24}>
                    <Row flexDirection="row-reverse">
                        <SeeAll href={`/club/${club_slug}/replays`} />
                    </Row>
                </Col>
            </Row>
            <Row padding="10px 0 0 0">
                <Col item={24}>
                    <ScrollingCarousel type="Replay">
                        {matches &&
                            matches.map((match: any, index: number) => {
                                const item: GameCardProps = {
                                    id: match.id,
                                    backgroundImage: thumbNailLink(
                                        match?.stream_info?.video_asset_id,
                                        500,
                                        match.thumbnail_url,
                                    ),
                                    clubImage1: match.home_team.club.logo,
                                    clubName1:
                                        match.home_team.club.display_name,
                                    clubImage2: match.away_team.club.logo,
                                    clubName2:
                                        match.away_team.club.display_name,
                                    leagueImage: match.league.logo,
                                    leagueName: match.league.name,
                                    match_round: match.round,
                                    roundName: match.round_name,
                                    matchName: match.name,
                                    date: match.start_datetime,
                                    mode: 'Replay',
                                    matchClubs: {
                                        match_id: match.id,
                                        home_club_id: match.home_team.club.id,
                                        away_club_id: match.away_team.club.id,
                                        home_slug: match.home_team.club.slug,
                                        away_slug: match.away_team.club.slug,
                                        league_slug: match.league.slug,
                                    },
                                }

                                return (
                                    <article key={`recentGames-${index}`}>
                                        <ThumbnailCard
                                            {...item}
                                            {...props}
                                            // handleClick={() => onHandleClick(match.id)}
                                        />
                                    </article>
                                )
                            })}
                    </ScrollingCarousel>
                </Col>
            </Row>
        </ReplayWrapper>
    )
}

export default RecentGamesView
