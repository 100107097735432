import { gql } from '@apollo/client'

const GET_PLAYERS = gql`
    query SubPlayers($where: players_details_bool_exp = {}) {
        players_details(where: $where) {
            id
            club_id
            user {
                id
                first_name
                last_name
            }
        }
    }
`

const GET_MEDIA_FILE = gql`
    query GetMediaFiles($where: media_gallery_bool_exp = {}) {
        media_gallery(where: $where) {
            id
            url
            playback_id
        }
    }
`

export default {
    GET_PLAYERS,
    GET_MEDIA_FILE,
}
