import React from 'react'

// import styled component
import { Text } from 'components/Text'
// import styles

import { UploadFileWrapper, UploadContentWrapper } from '../mediagallery.style'

export interface EmptyMediaProps {
    content?: string
}

const EmptyMedia = ({ content }: EmptyMediaProps) => {
    return (
        <UploadFileWrapper>
            <UploadContentWrapper>
                <Text
                    fColor="white"
                    fSize={0.8}
                    fWeight={700}
                    mode="p"
                    tAlign="center"
                >
                    {content}
                </Text>
            </UploadContentWrapper>
        </UploadFileWrapper>
    )
}

export default EmptyMedia
