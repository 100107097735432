import { useSubscription } from '@apollo/client'
import marker from 'assets/images/home/mark.png'
import ThumbnailCard from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { SeeAll } from 'components/Scroller/SeeAll'
import { SkeletonWrapper } from 'components/Skeleton'
import { Text } from 'components/Text'
import { subscribe } from 'graphql/match/index'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { GameCardProps } from 'types/components/GameCard'
import { isLive, thumbNailLink } from 'utils/common-helper'
import { isServer } from 'utils/helper'
import { GameDayWrapper } from './gameday.style'

const PlayerProfileLive: React.FC = (props: any) => {
    const router = useRouter()
    const { club_slug } = router.query
    const { clubInfo, initialData } = props
    const ssrData = initialData?.initialData?.liveUpcoming

    const [data, setData] = useState<null | Array<any>>(ssrData)
    const { loading } = useSubscription(subscribe.SUB_MATCHES, {
        skip: isServer || !clubInfo.id,
        variables: {
            where: {
                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: { _neq: true },
                },
                club_id: { _eq: clubInfo.id },
                _or: [
                    { away_club_id: { _eq: clubInfo.id } },
                    { home_team: { club_id: { _eq: clubInfo.id } } },
                ],
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setData(data.matches)
        },
    })

    const onHandleClick = (id: number) => {
        router.push(`/club/${clubInfo.slug}/match/${id}`)
    }
    return (
        <GameDayWrapper>
            <Row alignItems="center">
                <Col item={24}>
                    <Row>
                        <Text
                            fColor="white"
                            fSize={1.5}
                            fWeight={700}
                            mode="h2"
                        >
                            {'Live & Upcoming'}
                        </Text>
                    </Row>
                </Col>
                <Col item={24}>
                    <Row flexDirection="row-reverse">
                        <SeeAll href={`/club/${club_slug}/live`} />
                    </Row>
                </Col>
            </Row>

            <SkeletonWrapper
                mode="Match"
                data={data}
                emptyElement={<Text>No matches scheduled.</Text>}
                loading={_.isNull(data)}
            >
                {!_.isNull(data) && (
                    <Row padding="10px 0 0 0">
                        <Col item={24}>
                            <ScrollingCarousel type="Live">
                                {data.map((match: any, index: number) => {
                                    const item: GameCardProps = {
                                        id: match.id,
                                        backgroundImage: thumbNailLink(
                                            match.video_asset_id,
                                            500,
                                            match.thumbnail_url,
                                        ),
                                        clubImage1: match.home_team.club.logo,
                                        clubName1:
                                            match.home_team.club.display_name,
                                        clubImage2: match.away_team.club.logo,
                                        clubName2:
                                            match.away_team.club.display_name,
                                        leagueImage: match.league.logo
                                            ? match.league.logo
                                            : marker,
                                        leagueName: match.league.name,
                                        roundName: match.round_name,
                                        matchName: match.name,
                                        mode: 'Day',
                                        isLive: isLive(
                                            match.start_datetime,
                                            match?.stream_info?.status,
                                        ),
                                        users: 0, //TODO: get the number of users watching
                                        date: match.start_datetime,
                                        matchClubs: {
                                            match_id: match.id,
                                            home_club_id:
                                                match.home_team.club.id,
                                            away_club_id:
                                                match.away_team.club.id,
                                            home_slug:
                                                match.home_team.club.slug,
                                            away_slug:
                                                match.away_team.club.slug,
                                            league_slug: match.league.slug,
                                        },
                                        backgroundImageSport:
                                            match?.sport?.background_image?.url,
                                        backgroundImageLeague:
                                            match?.league?.background_image
                                                ?.url,
                                    }

                                    return (
                                        <article
                                            key={`game-day-view-key${index}`}
                                        >
                                            <ThumbnailCard
                                                {...item}
                                                {...props}
                                                // handleClick={() => onHandleClick(match.id)}
                                            />
                                        </article>
                                    )
                                })}
                            </ScrollingCarousel>
                        </Col>
                    </Row>
                )}
            </SkeletonWrapper>
        </GameDayWrapper>
    )
}

const mapStateToProps = (state: RootState) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(PlayerProfileLive)
