import { ScrollingCarousel } from 'components/ReactCarousel'
import _ from 'lodash'
import { useContext } from 'react'

// import styles
import { MediaBody } from 'theme/global.state'
import { SectionWrapper } from '../mediagallery.style'

// import components
import { Row } from 'components/Layout'
import { MediaGallerySkeleton } from 'components/Skeleton'
import { Text } from 'components/Text'
import { EmptyMedia, Images, UploadImage } from '../components'

// import constants
import { ImageFileTypes } from '../constants'

// context
import { MediaGalleryContext } from '../index'

const ImageLayout = () => {
    const { images, mediaGalleryAccess, loading } =
        useContext(MediaGalleryContext)

    if (_.isEmpty(images) && !mediaGalleryAccess && !loading) return <></>

    return (
        <SectionWrapper>
            <Row flexDirection="column">
                <Text
                    fColor="white"
                    fSize={1}
                    fWeight={700}
                    mode="p"
                    padding="5px"
                >
                    {'Images'}
                </Text>
                {loading ? (
                    <div style={{ padding: '10px' }}>
                        <ScrollingCarousel type="Media">
                            {[1, 2, 3, 4, 5, 6, 7].map((item: number) => {
                                return (
                                    <MediaBody key={`image-key-${item}`}>
                                        <MediaGallerySkeleton />
                                    </MediaBody>
                                )
                            })}
                        </ScrollingCarousel>
                    </div>
                ) : images.length > 0 ? (
                    <Images />
                ) : mediaGalleryAccess ? (
                    <UploadImage type="image" fileTypes={ImageFileTypes} />
                ) : (
                    <EmptyMedia content="No images uploaded" />
                )}
            </Row>
        </SectionWrapper>
    )
}

export default ImageLayout
