import ItemCard, {
    CardProps,
    ItemCardType,
} from 'components/Card/ItemCard/ItemCard'
import { Row } from 'components/Layout'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { Text } from 'components/Text'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import { SeeAll } from './SeeAll'

export interface ScrollerStyling {
    titleSize?: number
    padding?: string
}

export interface CardScrollerProps {
    title: string
    data: CardProps[] | undefined
    type: ItemCardType
    handleClick?: (card: CardProps) => any
    seeAllLink?: string
    styling?: ScrollerStyling
    emptyElement?: ReactNode
}

export function CardScroller(props: CardScrollerProps) {
    const {
        title,
        data,
        type,
        handleClick,
        seeAllLink,
        styling,
        emptyElement = <Text>No content available.</Text>,
    } = props

    return (
        <Row flexDirection="column" padding={styling?.padding ?? ''}>
            <CardTitleRow>
                <Text
                    fColor="white"
                    fSize={styling?.titleSize ?? 1.5}
                    fWeight={700}
                    mode="h2"
                    padding="0 0 10px 0"
                >
                    {title}
                </Text>
                {seeAllLink ? <SeeAll href={seeAllLink} /> : <></>}
            </CardTitleRow>
            {data && data.length ? (
                <ScrollingCarousel type={type}>
                    {data.map((card: CardProps, index: number) => {
                        return (
                            <CardWrapper
                                key={index}
                                onClick={() => handleClick && handleClick(card)}
                            >
                                <ItemCard {...card} type={type} />
                            </CardWrapper>
                        )
                    })}
                </ScrollingCarousel>
            ) : (
                <EmptySkeletonWrapper>{emptyElement}</EmptySkeletonWrapper>
            )}
        </Row>
    )
}

const CardWrapper = styled.li`
    margin-left: 5px;
    margin-right: 10px;

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        margin-left: 5px;
    }
`

const CardTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const EmptySkeletonWrapper = styled.div`
    /* min-height: 80px; */
    display: flex;
    /* justify-content: center; */
    text-align: left;
    font-size: 1.1rem;
    width: 100%;
    color: #9f9f9f;
`
