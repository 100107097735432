import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useUser } from '@auth0/nextjs-auth0'
import { Popover } from 'antd'
import { CLUB_USER_FOLLOWS } from 'graphql/club/users'
import * as userGQL from 'graphql/user'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { connect } from 'react-redux'

// component
import { Image } from 'components/Image'
import { SocialShare } from 'components/SocialShare'
import { Text } from 'components/Text'
import { FiDollarSign, FiShare2, FiUserMinus, FiUserPlus } from 'react-icons/fi'
// import styled component
import {
    FollowButton,
    FollowerText,
    HeadClubName,
    HeadWrapper,
    ShareButton,
    SupportButton,
} from './head.style'
// import utils
import { baseUrl } from 'utils/constData'
// import gql
import { mutate } from 'graphql/user'
// import hooks
import { siteSettings } from 'hooks'
import { clubEvent } from 'lib/tracking/events'
import { isServer } from 'utils/helper'

const HeadView: React.FC = (props: any) => {
    const { initialData, handleToggleSupportModal } = props
    const club = initialData?.initialData?.club
    const router = useRouter()
    const { asPath } = router
    const { user } = useUser()
    const [followed, setFollowed] = useState(false)
    const [followActive, setFollowActive] = useState(true)

    const [follow] = useMutation(mutate.USER_FOLLOW_CLUB)
    const [unfollow] = useMutation(mutate.USER_UNFOLLOW_CLUB)

    useSubscription(CLUB_USER_FOLLOWS, {
        skip: isServer,
        variables: {
            where: {
                // If there's no user (not logged in), need to return an empty list here so user id -1
                // Having user_id as undefined will return the FULL list of clubs, which is wasteful and not expected
                user_id: { _eq: user ? user.id : -1 },
            },
        },
        onData: ({ data: { data, loading } }) => {
            if (data.user_club_follows) {
                setFollowActive(true)
                setFollowed(
                    data.user_club_follows
                        .map((c: { club: { id: any } }) => c.club.id)
                        .includes(club.id),
                )
            }
        },
    })

    const { loading, data } = useQuery(userGQL.query.GET_USER_PASS, {
        variables: {
            where: {
                user_id: { _eq: user ? user.id : -1 },
                pass_id: { _eq: club ? club.pass?.id : -1 },
            },
        },
    })

    const supporting = () => {
        return data?.user_passes?.length > 0
    }

    const trackFollow = () => {
        clubEvent({
            props: {
                clubName: club?.name,
                message: 'Follow Club',
            },
        })
        setFollowActive(false)
    }
    const trackUnfollow = () => {
        clubEvent({
            props: {
                clubName: club?.name,
                message: 'Unfollow Club',
            },
        })
        setFollowActive(false)
    }
    const trackShareClub = () => {
        clubEvent({
            props: {
                clubName: club?.name,
                message: 'Share Club',
            },
        })
    }

    const handleFollow = () => {
        if (user) {
            if (followed) {
                trackUnfollow()
                unfollow({
                    variables: {
                        where: {
                            user_id: { _eq: user.id },
                            club_id: { _eq: club.id },
                        },
                    },
                })
            } else {
                trackFollow()
                follow({
                    variables: {
                        objects: {
                            user_id: user.id,
                            club_id: club.id,
                        },
                    },
                })
            }
        } else {
            router.push(`/api/auth/login?returnTo=${asPath}`)
        }
    }

    /** TODO: Refactor to use agregate */

    if (_.isEmpty(club)) {
        return <></>
    }

    return (
        <HeadWrapper>
            <div className="name-wrapper">
                <Image
                    src={club.logo}
                    width={89}
                    height={90}
                    alt={club?.name}
                />
                <HeadClubName>
                    <Text
                        fColor="white"
                        fWeight={800}
                        className="col-md"
                        mode="h1"
                    >
                        {club.name}
                    </Text>
                </HeadClubName>
            </div>

            <div className="button-wrapper">
                {siteSettings('components.follow_club_count') && (
                    <>
                        {club && (
                            <FollowerText>
                                <Text fSize={1}>{`${
                                    club.user_club_follows?.length || '0'
                                } Followers`}</Text>
                            </FollowerText>
                        )}
                    </>
                )}
                {siteSettings('components.fan_pass') && (
                    <>
                        {club.pass && (
                            <SupportButton
                                onClick={() => handleToggleSupportModal(true)}
                                bColor={supporting() ? 'gray' : 'warning'}
                                icon={<FiDollarSign />}
                                disabled={loading}
                            >
                                {supporting() ? 'Supporter' : 'Support Club'}
                            </SupportButton>
                        )}
                    </>
                )}

                <FollowButton
                    onClick={handleFollow}
                    bColor={followed ? 'gray' : 'warning'}
                    icon={followed ? <FiUserMinus /> : <FiUserPlus />}
                    disabled={!followActive}
                >
                    {followed ? 'Unfollow' : 'Follow Club'}
                </FollowButton>
                <Popover
                    content={<SocialShare url={`${baseUrl + router.asPath}`} />}
                    trigger={'click'}
                >
                    <ShareButton
                        onClick={trackShareClub}
                        bColor="primary"
                        bSize="small"
                        icon={<FiShare2 />}
                    >
                        {'Share'}
                    </ShareButton>
                </Popover>
            </div>
        </HeadWrapper>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    club: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(HeadView)
