import { Item } from '@trendyol-js/react-carousel/dist/types/types/carousel'
import {
    ScrollerGeneralType,
    ScrollingCarousel,
} from 'components/ReactCarousel'
import { EmptySkeletonWrapper } from 'components/Scroller/CardScroller'
import { Text } from 'components/Text'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { CardBody } from 'theme/global.state'
import { AvatarSkeleton } from '.'
import MatchSkeleton from './LiveMatch'

interface Props {
    mode: ScrollerGeneralType
    cards?: number
    onChange?: (isVisible: boolean) => void
    active?: boolean
    emptyElement?: string | ReactNode
    data: Array<any> | null
    children: Item
    backgroundColor?: string
}

const LazySkeletonWrapper: React.FC<Props> = ({
    mode = 'Replay',
    cards = 6,
    onChange,
    active = true,
    emptyElement = <Text>No data available</Text>,
    data,
    children,
    backgroundColor,
}) => {
    if (!data || data.length === 0) {
        return <EmptySkeletonWrapper>{emptyElement}</EmptySkeletonWrapper>
    }

    function renderSkeleton() {
        switch (mode) {
            case 'Avatar':
                return <AvatarSkeleton />
            case 'Live':
            case 'Replay':
            case 'Clip':
                return <MatchSkeleton mode={mode} />
            default:
                return null
        }
    }

    return (
        <ScrollingCarousel backgroundColor={backgroundColor} type={mode}>
            {children}

            {active ? (
                <VisibilitySensor
                    active={active}
                    delayedCall={true}
                    onChange={onChange}
                >
                    <>
                        {_.range(cards).map((item: number) => {
                            return (
                                <CardBody key={`load-more-skeletor${item}`}>
                                    {renderSkeleton()}
                                </CardBody>
                            )
                        })}
                    </>
                </VisibilitySensor>
            ) : (
                <></>
            )}
        </ScrollingCarousel>
    )
}

export default LazySkeletonWrapper
