import { SlideArrow } from 'components/Button/Button'
import { ItemCardType } from 'components/Card/ItemCard/ItemCard'
import { VideoType } from 'components/Scroller/VideoScroller/ReplayScroller'
import { useRouter } from 'hooks'
import { scrollerScrollEvent } from 'lib/tracking/events/scroll'
import React, {
    FunctionComponent,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import styled from 'styled-components'
import { getOuterWidth } from '../../components/ReactCarousel/helpers/'
import styles from '../../components/ReactCarousel/styles/slider/styles.module.css'
import { SlideDirection } from '../../components/ReactCarousel/types/carousel'

export type ScrollerGeneralType = VideoType | ItemCardType | 'Media'

export const ScrollingCarousel: FunctionComponent<SliderProps> = ({
    children,
    className,
    type,
    backgroundColor = 'rgba(19, 18, 20, 1)',
    leftIcon = (
        <SlideArrow position="left" backgroundColor={backgroundColor}>
            <AiFillLeftCircle
                className="scroll-arrow"
                style={{
                    marginLeft: 'auto',
                    width: '100%' /* Add this to make the icon take up full width of the parent container */,
                    height: '100%',
                }}
            />
        </SlideArrow>
    ),
    rightIcon = (
        <SlideArrow position="right" backgroundColor={backgroundColor}>
            <AiFillRightCircle
                className="scroll-arrow"
                style={{
                    marginLeft: 'auto',
                    width: '100%' /* Add this to make the icon take up full width of the parent container */,
                    height: '100%',
                }}
            />
        </SlideArrow>
    ),
}: SliderProps) => {
    const slider = useRef<HTMLDivElement>(null)
    const [isDown, setIsDown] = useState(false)
    const [position, setPosition] = useState({
        startX: 0,
        scrollLeft: 0,
    })
    const [cardsScrolled, setCardsScrolled] = useState(-1)
    const { asPath: page } = useRouter()

    useEffect(() => {
        const scrollTimer = setTimeout(() => {
            cardsScrolled >= 0 &&
                scrollerScrollEvent({
                    type: type,
                    offset: cardsScrolled,
                    page: page,
                    gtm: true,
                    datadog: true,
                })
        }, 650)
        return () => clearTimeout(scrollTimer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardsScrolled])

    const showArrows = (): Arrows => {
        const sliderElement = slider.current
        return {
            left: !!sliderElement && sliderElement.scrollLeft > 0,
            right:
                !!sliderElement &&
                sliderElement.scrollWidth >
                    sliderElement.scrollLeft + sliderElement.offsetWidth,
        }
    }
    const [showArrow, setShowArrow] = useState<Arrows>(showArrows())

    const onScroll = (_: Event) => {
        setShowArrow(showArrows())
        const scrollLeft = slider.current?.scrollLeft
        const childWidth = getOuterWidth(
            slider.current!.children[0] as HTMLElement,
        )
        if (scrollLeft) {
            setCardsScrolled(Math.floor(scrollLeft / childWidth))
        }
    }

    const ref = useCallback(
        (node: any) => {
            if (node !== null) {
                Object.defineProperty(slider, 'current', { value: node })
                setShowArrow(showArrows())
                node.addEventListener('scroll', onScroll)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [slider, children],
    )

    const mouseDown = (e: React.MouseEvent) => {
        setIsDown(true)
        setPosition({
            startX: e.pageX - slider.current!.offsetLeft,
            scrollLeft: slider.current!.scrollLeft,
        })
    }

    const mouseUp = (_: React.MouseEvent) => {
        setIsDown(false)
        setShowArrow(showArrows())
        slider.current!.classList.remove(styles.sliding)
    }

    const mouseMove = (e: React.MouseEvent) => {
        if (!isDown) return
        e.preventDefault()
        slider.current!.classList.add(styles.sliding)
        const eventPosition = e.pageX - slider.current!.offsetLeft
        const slide = eventPosition - position.startX

        slider.current!.scrollLeft = position.scrollLeft - slide
    }

    const calculateSlideAmount = (direction: SlideDirection): number => {
        const childWidth = getOuterWidth(
            slider.current!.children[0] as HTMLElement,
        )
        return direction === SlideDirection.Left ? childWidth : -childWidth
    }

    const slide = (direction: SlideDirection) => {
        const slideAmount = calculateSlideAmount(direction)
        const start = slider.current!.scrollLeft
        smoothHorizontalScroll(300, slideAmount, start)
    }

    const smoothHorizontalScroll = (
        time: number,
        amount: number,
        start: number,
    ) => {
        let curTime = 0
        for (let scrollCounter = 0; curTime <= time; scrollCounter++) {
            window.setTimeout(
                smoothHorizontalScrollBehavior,
                curTime,
                (scrollCounter * amount) / 100 + start,
            )
            curTime += time / 100
        }
    }

    const smoothHorizontalScrollBehavior = (amount: number) => {
        slider.current!.scrollLeft = amount
    }

    const getArrow = (
        direction: SlideDirection,
        data: string,
        element?: ReactElement,
    ) => {
        return (
            <div data-arrow={data} onClick={() => slide(direction)}>
                {element ?? <button />}
            </div>
        )
    }

    return (
        <CarouselWrapper
            className={`${styles.sliderBase} ${className}`}
            data-testid="carousel"
        >
            {showArrow.left && getArrow(SlideDirection.Right, 'left', leftIcon)}
            {showArrow.right &&
                getArrow(SlideDirection.Left, 'right', rightIcon)}
            <ul
                className={styles.slider}
                ref={ref}
                onMouseDown={mouseDown}
                onMouseLeave={mouseUp}
                onMouseUp={mouseUp}
                onMouseMove={mouseMove}
            >
                {children}
            </ul>
        </CarouselWrapper>
    )
}

export interface SliderProps {
    className?: string
    leftIcon?: ReactElement
    rightIcon?: ReactElement
    backgroundColor?: string
    type: ScrollerGeneralType
    children?: ReactNode
}

export type Arrows = {
    left: boolean
    right: boolean
}

const CarouselWrapper = styled.div`
    min-height: 70px;
    &:hover .scroll-arrow {
        opacity: 0.8;
    }
`
