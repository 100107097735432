import { combineEventsAndMatches } from 'utils/common-helper'
import { get } from './helpers/request'
import { EventOrMatch, Games } from './types/games'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL
const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL

export async function getAllGames(limit: number) {
    try {
        const response = await get<Games>(
            `${baseUrl}/games/cards/${encodeURIComponent(limit)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

// Temporary function - need to put this filtering into the actual query rather than here on the front end
export async function getClubGames(limit: number, clubId: number) {
    try {
        const response = await get<Games>(
            `${baseUrl}/games/cards/${encodeURIComponent(limit)}`,
        )

        const filteredMatches = response.parsedBody?.matches?.filter((m) => {
            return m.club_id === clubId
        })

        const filteredEvents = response.parsedBody?.event_streams?.filter(
            (e) => {
                return e.club_id === clubId
            },
        )

        const filteredGames = {
            matches: filteredMatches ? filteredMatches : null,
            event_streams: filteredEvents ? filteredEvents : null,
        }

        return filteredGames
    } catch (error) {
        console.log(error)
        return undefined
    }
}

// Temporary function - need to put this filtering into the actual query rather than here on the front end
export async function getLeagueGames(limit: number, leagueId: number) {
    try {
        const response = await get<Games>(
            `${baseUrl}/games/cards/${encodeURIComponent(limit)}`,
        )

        const filteredMatches = response.parsedBody?.matches?.filter((m) => {
            return m.league_id === leagueId
        })

        const filteredEvents = response.parsedBody?.event_streams?.filter(
            (e) => {
                return e.league_id === leagueId
            },
        )

        const filteredGames = {
            matches: filteredMatches ? filteredMatches : null,
            event_streams: filteredEvents ? filteredEvents : null,
        }

        return filteredGames
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function searchEventsAndMatches(searchInput = '', sport = '') {
    try {
        const response = await get<Games>(
            `${baseUrl}/games/cards/search/%25${encodeURIComponent(
                searchInput,
            )}%25/sport/%25${encodeURIComponent(sport)}%25`,
        )
        return combineEventsAndMatches(
            response.parsedBody?.event_streams,
            response.parsedBody?.matches,
        ) as EventOrMatch[]
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getReplays(
    limit: number,
    offset = 0,
    searchInput = '',
    sport = '',
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/replays-search?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(offset)}&search=${encodeURIComponent(
                searchInput,
            )}&sport=${encodeURIComponent(sport)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getLeagueReplays(
    limit: number,
    offset: number,
    league: number,
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/replays-by-league?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(
                offset,
            )}&leagueId=${encodeURIComponent(league)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getClubReplays(
    limit: number,
    offset: number,
    club: number,
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/replays-by-club?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(offset)}&clubId=${encodeURIComponent(
                club,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getTeamReplays(
    limit: number,
    offset: number,
    team: number,
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/replays-by-team?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(offset)}&teamId=${encodeURIComponent(
                team,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getCommunityReplays(
    limit: number,
    offset: number,
    communityId: number,
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/replays-by-community?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(
                offset,
            )}&communityId=${encodeURIComponent(communityId)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getCommunityUpcoming(
    limit: number,
    offset: number,
    communityId: number,
) {
    try {
        const response = await get<Games>(
            `${apiBaseUrl}/matches/webhook/upcoming-by-community?limit=${encodeURIComponent(
                limit,
            )}&offset=${encodeURIComponent(
                offset,
            )}&communityId=${encodeURIComponent(communityId)}`,
        )
        return combineEventsAndMatches(
            response.parsedBody?.event_streams,
            response.parsedBody?.matches,
        ) as EventOrMatch[]
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getStreamArchivedStatus(streamInfoId: number) {
    try {
        const response = await get<{ streams: { archived: boolean }[] }>(
            `${baseUrl}/streams/archived/id/${encodeURIComponent(
                streamInfoId,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}
