import { useSubscription } from '@apollo/client'
import ThumbnailCard from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { FeatureClip_Modal } from 'components/Modal/FeatureClip/feature-clip'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { Text } from 'components/Text'
import { CLIPS } from 'graphql/club'
import { useRouter } from 'hooks'
import { StreamPageContext } from 'hooks/context/StreamPageContext'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setClipList } from 'redux/actions/clip'
import { setVideoAutoplay } from 'redux/actions/site'
import { SelectedClipType } from 'types/common/clips'
import { FeaturedClip } from 'types/components/FeaturedClip'
import { isServer } from 'utils/helper'
import { ClipWrapper, NoContentWrapper } from './clip.style'

export const ClipView: React.FC = (props: any) => {
    const { clubId, playerDetailId, player, club_slug, event, initialData } =
        props
    const ssrData = initialData?.initialData?.clips
    const dispatch = useDispatch()

    const { query } = useRouter()
    const formatDataMapping = (rawData: any) => {
        const matchDateTime = (clipData: any) => {
            if (clipData?.match) {
                return clipData?.match?.start_datetime
            }
            if (clipData?.event_stream) {
                return clipData?.event_stream?.start_datetime
            }
            return ''
        }

        const description = (clipData: any) => {
            if (clipData?.match) {
                return _.isNull(clipData.match)
                    ? ''
                    : clipData.match?.home_team.club.display_name ===
                        clipData.match?.away_team.club.display_name
                      ? `${clipData.match?.home_team.club.display_name} - ${clipData.match?.round_name}`
                      : clipData?.match?.custom_opponent
                        ? `${clipData.match?.home_team.club.display_name} vs ${clipData.match?.custom_opponent?.club_display_name} - ${clipData.match?.round_name}`
                        : `${clipData.match?.home_team.club.display_name} vs ${clipData.match?.away_team.club.display_name} - ${clipData.match?.round_name}`
            }
            if (clipData?.event_stream) {
                return clipData?.event_stream?.name
            }
            return ''
        }

        const formatData = rawData?.map((clipAsset: any) => ({
            id: clipAsset.clip_asset.id,
            leagueName: clipAsset?.clip_asset?.match
                ? clipAsset?.clip_asset?.match?.league?.name
                : clipAsset?.clip_asset?.event_stream?.league?.name,
            clubInfo: clipAsset.club,
            clubId: clipAsset.club_id,
            playbackId: clipAsset.clip_asset.playback_id,
            assetId: clipAsset.clip_asset.asset_id,
            matchId: clipAsset.clip_asset.match?.id,
            staticFileStatus: clipAsset.clip_asset.static_file_status,
            backgroundImage: {
                src:
                    'https://image.mux.com/' +
                    clipAsset.clip_asset.playback_id +
                    '/thumbnail.png?width=500',
                height: 314,
                width: 178,
            },
            title: clipAsset.clip_asset.name,
            desc: description(clipAsset?.clip_asset),
            homeClubName: _.isNull(clipAsset?.clip_asset?.match)
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.name,
            awayClubName: _.isNull(clipAsset?.clip_asset?.match)
                ? ''
                : clipAsset?.clip_asset?.match?.away_team?.club?.name,
            homeClubDisplayName: _.isNull(clipAsset.clip_asset.match)
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.display_name,
            awayClubDisplayName: _.isNull(clipAsset.clip_asset.match)
                ? ''
                : clipAsset?.clip_asset.match?.away_team?.club?.display_name,
            homeClubSlug: _.isNull(clipAsset.clip_asset.match)
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.slug,
            awayClubSlug: _.isNull(clipAsset.clip_asset.match)
                ? ''
                : clipAsset?.clip_asset.match?.away_team?.club?.slug,
            matchDateTime: matchDateTime(clipAsset?.clip_asset),
            submittedBy: clipAsset?.clip_asset?.user
                ? `${clipAsset?.clip_asset?.user?.first_name} ${clipAsset?.clip_asset?.user?.last_name}`
                : 'N/A',
        }))
        return formatData
    }

    const formattedSsrData = formatDataMapping(ssrData)
    const [modalFlag, setModalFlag] = useState<boolean>(false)
    const [clips, setClips] = useState<null | Array<any>>(formattedSsrData)
    const [selectedClip, setSelectedClip] = useState<SelectedClipType>()
    const { playerInstance } = useContext(StreamPageContext)

    useEffect(() => {
        if (!_.isUndefined(query?.fc)) {
            const clipObject = _.find(clips, ['playbackId', query?.fc])
            if (!_.isUndefined(clipObject)) {
                setSelectedClip(clipObject as SelectedClipType)
                setModalFlag(true)
            }
        }
    }, [clips])

    const onCloseModal = () => {
        setModalFlag(false)
        setSelectedClip({} as SelectedClipType)
    }

    const onClipClick = (item: FeaturedClip) => {
        playerInstance?.pause()
        dispatch(setVideoAutoplay(true))
        setModalFlag(true)
        setSelectedClip(item as SelectedClipType)
    }

    useSubscription(CLIPS.SUB_PLAYER_FEATURE_CLIPS, {
        skip: isServer || !playerDetailId || !clubId,
        variables: {
            where: {
                players_id: { _eq: playerDetailId },
                club_id: { _eq: clubId },
            },
        },
        onData: ({ data: { data, loading } }) => {
            if (data.clip_asset_players) {
                const clipData = data?.clip_asset_players
                const formatedClips = formatDataMapping(clipData)
                setClips(formatedClips)
                dispatch(setClipList(formatedClips))
            }
        },
    })

    // if (loading) return <></>;
    // if (_.isEmpty(data?.clip_asset_players)) return <></>;
    // if (error) return <div>Error!</div>;

    return (
        <ClipWrapper>
            <Row alignItems="center" justifyContent="space-between">
                <Text fColor="white" fSize={1.375} fWeight={700} mode="h2">
                    {'Featured Clips'}
                </Text>
            </Row>

            <FeatureClip_Modal
                {...selectedClip}
                setClips={setClips}
                show={modalFlag}
                handleClose={() => onCloseModal()}
            />

            {_.isEmpty(clips) && (
                <NoContentWrapper>
                    <Text fColor="white" fSize={1.2}>
                        {'No clips available.'}
                    </Text>
                </NoContentWrapper>
            )}

            <Row padding="10px 0 0 0">
                <Col item={24}>
                    {!_.isNull(clips) && (
                        <ScrollingCarousel type="Clip">
                            {clips?.map((item: FeaturedClip, index: number) => {
                                return (
                                    <article key={`clips-card-` + index}>
                                        <ThumbnailCard
                                            {...item}
                                            {...props}
                                            mode="Clip"
                                            key={index}
                                            event={event}
                                            handleClick={() =>
                                                onClipClick(item)
                                            }
                                            href={`/club/${club_slug}/player/${player?.slug}/clips/${item?.playbackId}`}
                                        />
                                    </article>
                                )
                            })}
                        </ScrollingCarousel>
                    )}
                </Col>
            </Row>
        </ClipWrapper>
    )
}
