import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const schema = z.object({
    playerInitTime: z.union([z.string(), z.number()]),
    playerReadyTime: z.union([z.string(), z.number()]).optional(),
})

export type VideoTimeSchema = z.infer<typeof schema>

const eventName = 'video.initTime'

export function videoPlayerTimeEvent({
    time,
    gtm,
    datadog,
}: {
    time: VideoTimeSchema
    gtm?: boolean
    datadog?: boolean
}) {
    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(time),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            videoTime: time,
        })
}
