import { ScrollingCarousel } from 'components/ReactCarousel'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'

// import components

// import styled component
import ImageModal from 'components/Modal/ImageModal'
import { MediaBody } from 'theme/global.state'
import { FileWrapper, ImageHolder, SectionWrapper } from '../mediagallery.style'
import { UploadImage } from './index'

// import context
import { MediaGalleryContext } from '../index'

// import constants
import { ImageFileTypes } from '../constants'

// hooks
import { useRouter, useUser } from 'hooks'
import { mediaEvent } from 'lib/tracking/events/media'
import { ClubContext } from 'pages/club/[club_slug]'
import { PlayerContext } from 'pages/club/[club_slug]/player/[player_slug]'
import { getCurrentPageName } from 'utils/common-helper'

export interface MediaInfoType {
    mediaId: number | null
    mediaSrc?: string
    clubTagId?: number | null
    clubTagName?: string
    playersTag?: any
}

const Images = () => {
    const { mediaGalleryAccess, images, mode } = useContext(MediaGalleryContext)
    const { query, router } = useRouter()
    const [modalFlag, setModalFlag] = useState<boolean>(false)
    const [selectedMediaInfo, setSelectedMediaInfo] = useState<MediaInfoType>(
        {} as MediaInfoType,
    )
    const { user } = useUser()
    const club = useContext(ClubContext)
    const player = useContext(PlayerContext)

    useEffect(() => {
        // get the info of the image based on the URL query
        function hydrateSelectedImage() {
            if (_.isUndefined(query?.mg)) return

            const mediaObject = _.find(images, [
                'id',
                parseInt(query?.mg as string),
            ])

            if (_.isUndefined(mediaObject)) return

            setModalFlag(true)
            setSelectedMediaInfo({
                mediaId: mediaObject?.id,
                mediaSrc: mediaObject?.url,
                clubTagId: mediaObject?.media_gallery_clubs[0]?.club_id,
                clubTagName:
                    mediaObject?.media_gallery_clubs[0]?.club?.name ?? null,
                playersTag: mediaObject?.media_gallery_players,
            })
        }

        hydrateSelectedImage()
    }, [images])

    const onCloseModal = () => {
        setModalFlag(false)
        setSelectedMediaInfo({} as MediaInfoType)
    }

    const onImageClick = (src: any) => {
        getCurrentPageName(router.pathname) === 'Club Page' &&
            mediaEvent({
                props: {
                    clubName: club?.name,
                    message: 'Views asset in Media Gallery',
                },
            })
        getCurrentPageName(router.pathname) === 'Player Profile Page' &&
            mediaEvent({
                props: {
                    playerName: `${player?.player?.user?.first_name} ${player?.player?.user?.last_name}`,

                    message: 'View content in Media Gallery',
                },
            })
        setModalFlag(true)
        setSelectedMediaInfo({
            mediaId: src.id,
            mediaSrc: src.url,
            clubTagId: src.media_gallery_clubs[0]?.club_id,
            clubTagName: src.media_gallery_clubs[0]?.club?.name ?? null,
            playersTag: src.media_gallery_players,
        })
    }

    return (
        <SectionWrapper>
            <ImageModal
                show={modalFlag}
                mode={mode}
                access={mediaGalleryAccess}
                {...selectedMediaInfo}
                handleClose={() => onCloseModal()}
            />
            <ScrollingCarousel type="Media">
                {images.map(
                    (image: { url: string | undefined }, index: any) => (
                        <MediaBody key={`image-key-${index}`}>
                            <FileWrapper onClick={() => onImageClick(image)}>
                                <ImageHolder src={image.url} />
                            </FileWrapper>
                        </MediaBody>
                    ),
                )}
                {mediaGalleryAccess && (
                    <UploadImage type="image" fileTypes={ImageFileTypes} />
                )}
            </ScrollingCarousel>
        </SectionWrapper>
    )
}

export default Images
