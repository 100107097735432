import { gql } from '@apollo/client'
const GET_CLIPS = gql`
    query GetClipsQuery($where: clip_assets_bool_exp = {}) {
        clip_assets(where: $where) {
            user_id
            updated_at
            thumbnail_url
            playback_id
            name
            match_id
            id
            created_at
            asset_id
            clip_asset_user_clubs {
                club {
                    display_name
                    logo
                    id
                    slug
                }
            }
            event_stream {
                league {
                    id
                    name
                    slug
                }
                name
                slug
            }
            match {
                round_name
                league {
                    slug
                    name
                    id
                }
                home_team {
                    club {
                        display_name
                        slug
                        name
                    }
                }
                away_team {
                    club {
                        display_name
                        slug
                        name
                    }
                }
                created_at
                id
            }
            static_file_status
            user {
                first_name
                last_name
            }
            event_streams_id
        }
    }
`

const USER_SAVED_CLIP = gql`
    query GetUserSavedClips($where: saved_clips_bool_exp = {}, $limit: Int) {
        saved_clips(
            limit: $limit
            order_by: { created_at: desc }
            where: $where
        ) {
            clip_asset {
                id
                name
                playback_id
            }
        }
    }
`

const CLIP_SAVE_COUNT = gql`
    query ClipSavedCount($where: saved_clips_bool_exp = {}) {
        saved_clips_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

// ---------
export default {
    GET_CLIPS,
    USER_SAVED_CLIP,
    CLIP_SAVE_COUNT,
}
