import { gql } from '@apollo/client'

export const USER_SAVE_CLIP = gql`
    mutation InsertClipAssetUserClubMutation(
        $objects: [saved_clips_insert_input!] = {}
    ) {
        insert_saved_clips(objects: $objects) {
            affected_rows
            returning {
                id
                clip_id
            }
        }
    }
`

export const USER_UNSAVE_CLIP = gql`
    mutation UnsaveClipMutation($where: saved_clips_bool_exp = {}) {
        delete_saved_clips(where: $where) {
            affected_rows
            returning {
                id
                clip_asset {
                    id
                    asset_id
                }
            }
        }
    }
`
