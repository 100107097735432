import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const leagueSchema = z.object({
    leagueName: z.string().optional(),
    message: z.string().optional(),
})
export type LeagueSchema = z.infer<typeof leagueSchema>

export function leagueEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: LeagueSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'league.select'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: leagueSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}
