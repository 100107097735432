import { Row } from 'components/Layout'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const StyledModal = styled.div<{ show: boolean }>`
    position: fixed;
    display: flex;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalContent = styled.div`
    border-radius: 20px;
    border: 2px solid rgb(39 37 35);
    background: rgba(30, 30, 30, 0.8);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 21%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 40%;
    max-width: 444px;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 50%;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 80%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1.5;
    width: 90%;
`

export const ModalFooter = styled(Row)`
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    transition: 0;
`
