import {
    ScrollerGeneralType,
    ScrollingCarousel,
} from 'components/ReactCarousel'
import { EmptySkeletonWrapper } from 'components/Scroller/CardScroller'
import { Text } from 'components/Text'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { CardBody } from 'theme/global.state'
import { AvatarSkeleton } from '.'
import MatchSkeleton from './LiveMatch'

interface Props {
    loading: boolean
    emptyElement: ReactNode
    mode: ScrollerGeneralType
    cards?: number
    data: Array<any> | null
    children?: ReactNode
}

const SkeletonWrapper: React.FC<Props> = ({
    loading,
    emptyElement,
    mode = 'Match',
    cards = 6,
    data,
    children,
}) => {
    function renderSkeleton() {
        switch (mode) {
            case 'Avatar':
                return <AvatarSkeleton />
            case 'Live':
            case 'Replay':
            case 'Clip':
                return <MatchSkeleton mode={mode} />
            default:
                return null
        }
    }

    if (loading) {
        return (
            <ScrollingCarousel type={mode}>
                {_.range(cards).map((item: number) => (
                    <CardBody key={`skeleton-${mode}-view-key${item}`}>
                        {renderSkeleton()}
                    </CardBody>
                ))}
            </ScrollingCarousel>
        )
    }

    if (_.isEmpty(data)) {
        return (
            <EmptySkeletonWrapper>
                {emptyElement ?? <Text>No content available.</Text>}
            </EmptySkeletonWrapper>
        )
    }

    return <>{children}</>
}

export default SkeletonWrapper
