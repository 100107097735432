import { useSubscription } from '@apollo/client'
import marker from 'assets/images/home/mark.png'
import {
    default as ThumbCard,
    default as ThumbnailCard,
} from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { SeeAll } from 'components/Scroller/SeeAll'
import { SkeletonWrapper } from 'components/Skeleton'
import { Text } from 'components/Text'
import { subscribe } from 'graphql/match/index'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setVideoAutoplay } from 'redux/actions/site'
import { RootState } from 'redux/reducers/rootReducer'
import { EventCardProps, GameCardProps } from 'types/components/GameCard'
import { isLive, thumbNailLink } from 'utils/common-helper'
import { isServer } from 'utils/helper'
import { sortLiveStream } from 'utils/helper-date'

const GameDayView: React.FC = (props: any) => {
    const router = useRouter()
    const { club_slug, team_slug } = router.query
    const { clubInfo, initialData } = props
    const ssrData = initialData?.initialData?.matches
    const dispatch = useDispatch()
    const [matchData, setMatchData] = useState<null | Array<any>>()
    const [eventData, setEventData] = useState<null | Array<any>>()
    const [data, setData] = useState<null | Array<any>>([])

    useEffect(() => {
        const result =
            eventData || matchData
                ? sortLiveStream([...(eventData ?? []), ...(matchData ?? [])])
                : sortLiveStream(ssrData)
        setData(result)
    }, [eventData, matchData, ssrData])

    const { loading: matchLoading } = useSubscription(subscribe.SUB_MATCHES, {
        skip: isServer || (!team_slug && !clubInfo.id), //skip if there is neither team_slug nor clubInfo id
        variables: {
            where: {
                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: { _neq: true },
                },
                _or: team_slug
                    ? [
                          { away_team: { slug: { _eq: team_slug } } },
                          { home_team: { slug: { _eq: team_slug } } },
                      ]
                    : [
                          { away_club_id: { _eq: clubInfo.id } },
                          { home_team: { club_id: { _eq: clubInfo.id } } },
                      ],
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && clubInfo.id && data && setMatchData(data.matches)
        },
    })

    const { loading: eventLoading } = useSubscription(
        subscribe.SUB_EVENT_STREAMS,
        {
            skip: Boolean(team_slug) || isServer,
            variables: {
                where: {
                    is_private: { _eq: false },
                    stream_info: {
                        status: { _neq: 'completed' },
                        is_historic: { _eq: false },
                        archived: { _neq: true },
                    },
                    club_id: { _eq: clubInfo.id },
                },
            },
            onData: ({ data: { data, loading } }) => {
                !loading &&
                    clubInfo.id &&
                    data &&
                    setEventData(data.event_streams)
            },
        },
    )

    useEffect(() => {
        setMatchData(null)
        setEventData(null)
    }, [club_slug])

    return (
        <>
            <Row alignItems="center" padding="0 0 10px 0">
                <Col item={24}>
                    <Text fColor="white" fSize={1.5} fWeight={700} mode="h2">
                        {'Live & Upcoming'}
                    </Text>
                </Col>
                <Col item={24}>
                    <Row flexDirection="row-reverse">
                        <SeeAll href={`/club/${club_slug}/live`} />
                    </Row>
                </Col>
            </Row>

            <SkeletonWrapper
                mode="Match"
                data={data}
                emptyElement={<Text>No matches scheduled.</Text>}
                loading={_.isNull(data)}
            >
                {!_.isNull(data) && (
                    <ScrollingCarousel type="Live">
                        {data?.map((match: any, index: number) => {
                            const isEvent =
                                !match?.home_team_id && !match?.away_team_id
                            if (isEvent) {
                                const backgroundImageSport =
                                    match?.event_type === 'club'
                                        ? match?.club?.sport?.background_image
                                              ?.url
                                        : match?.league?.sports
                                              ?.background_image?.url
                                const item: EventCardProps = {
                                    id: match.id,
                                    event_name: match.name,
                                    backgroundImage: thumbNailLink(
                                        match.stream_info.video_asset_id,
                                        500,
                                        match.image,
                                    ),
                                    mode: 'Event',
                                    event_type: match?.event_type,
                                    isLive: isLive(
                                        match.start_datetime,
                                        match.stream_info.status,
                                    ),
                                    users: 0, //TODO: get the number of users watching
                                    date: match.start_datetime,
                                    is_event: true,
                                    clubImage1: match?.club?.logo,
                                    clubName1: match?.club?.display_name,
                                    event_slug: match?.slug,
                                    league_slug: match?.league?.slug,
                                    clubName: match?.club?.name,
                                    leagueName: match?.league?.name,
                                    leagueImage: match?.league?.logo
                                        ? match?.league?.logo
                                        : marker,
                                    club_slug: match?.club.slug,
                                    backgroundImageSport,
                                    backgroundImageLeague:
                                        match?.league?.background_image?.url,
                                }

                                return (
                                    <div
                                        key={`game-day-view-key${index}`}
                                        onClick={() =>
                                            dispatch(setVideoAutoplay(true))
                                        }
                                    >
                                        <ThumbCard {...item} {...props} />
                                    </div>
                                )
                            } else {
                                const item: GameCardProps = {
                                    id: match.id,
                                    sport: match.home_team.sport.name,
                                    backgroundImage: thumbNailLink(
                                        match?.stream_info?.video_asset_id,
                                        500,
                                        match.thumbnail_url,
                                    ),
                                    clubImage1: match.home_team.club.logo,
                                    clubName1:
                                        match.home_team.club.display_name,
                                    clubImage2: match.away_team.club.logo,
                                    clubName2:
                                        match.away_team.club.display_name,
                                    leagueImage: match.league.logo
                                        ? match.league.logo
                                        : marker,
                                    leagueName: match.league.name,
                                    roundName: match.round_name,
                                    matchName: match.name,
                                    mode: 'Day',
                                    isLive: isLive(
                                        match.start_datetime,
                                        match?.stream_info?.status,
                                    ),
                                    users: 0, //TODO: get the number of users watching
                                    date: match.start_datetime,
                                    matchClubs: {
                                        match_id: match.id,
                                        home_club_id: match.home_team.club.id,
                                        away_club_id: match.away_team.club.id,
                                        home_slug: match.home_team.club.slug,
                                        away_slug: match.away_team.club.slug,
                                        league_slug: match.league.slug,
                                    },
                                    customOpponent: match.custom_opponent,
                                    is_event: match.is_event,
                                    backgroundImageSport:
                                        match?.sport?.background_image?.url,
                                    backgroundImageLeague:
                                        match?.league?.background_image?.url,
                                }

                                return (
                                    <div
                                        key={`game-day-view-key${index}`}
                                        onClick={() =>
                                            dispatch(setVideoAutoplay(true))
                                        }
                                    >
                                        <ThumbnailCard {...item} {...props} />
                                    </div>
                                )
                            }
                        })}
                    </ScrollingCarousel>
                )}
            </SkeletonWrapper>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    clubInfo: state.club.info,
})

const mapDispatchToProps = {
    setVideoAutoplay: setVideoAutoplay,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(GameDayView)
