import { Skeleton } from 'antd'
import { VideoType } from 'components/Scroller/VideoScroller/ReplayScroller'
import React from 'react'
import { CardContent, CardWrapper } from 'theme/global.state'
import { AvartarContent, StyleContent } from './index.style'

interface Props {
    mode: VideoType
}

const MatchSkeleton: React.FC<Props> = ({ mode = 'Replay' }) => {
    // console.log(mode)
    return (
        <CardWrapper>
            <CardContent>
                <StyleContent>
                    <Skeleton.Button active block />
                    {!['Replay', 'Clip'].includes(mode) ? (
                        <AvartarContent>
                            <Skeleton.Avatar active shape="circle" />
                            <Skeleton.Avatar active shape="circle" />
                        </AvartarContent>
                    ) : null}
                </StyleContent>
            </CardContent>
        </CardWrapper>
    )
}

export default MatchSkeleton
