import { useMutation } from '@apollo/client'
import { Form, Input } from 'antd'
import { Button } from 'components/Button'
import { Col } from 'components/Layout'
import { Text } from 'components/Text'
import { mutate } from 'graphql/mediaGallery'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
    StyledModal,
} from './index.style'

const EditClipNameModal = (props: any) => {
    const [updateClipName] = useMutation(mutate.UPDATE_CLIP_NAME, {
        onCompleted() {
            props.handleSuccessEditName(props.clipID)
        },
        onError(e) {
            toast.error(`Something went wrong. Please try again later.`)
        },
    })
    const OnFinish = (value: any) => {
        console.log(value)

        if (props.title === props.form.getFieldValue('name')) {
            props.setShow(false)
            return
        }

        props.clipID &&
            updateClipName({
                variables: {
                    where: {
                        id: {
                            _eq: props.clipID,
                        },
                    },
                    _set: {
                        name: value.name,
                    },
                },
            })
    }
    const handleSave = () => {
        props.form.submit()
    }

    useEffect(() => {
        props.form.setFieldValue('name', props.title)
    }, [props.title])
    return (
        <StyledModal show={props.show}>
            <ModalContent>
                <ModalHeader>
                    <Col
                        style={`
                            width: 100%;
                        `}
                    >
                        <Text fSize={1.2} fWeight={600}>
                            Update Clip Name
                        </Text>
                        <Form
                            form={props.form}
                            layout="vertical"
                            onFinish={OnFinish}
                        >
                            <Form.Item
                                name={'name'}
                                label={
                                    <Text
                                        fSize={1}
                                        fWeight={700}
                                        style={`
                                            color: rgb(149 149 149);
                                        `}
                                    >
                                        Name
                                    </Text>
                                }
                            >
                                <Input
                                    size={'large'}
                                    style={{
                                        transition: 'none',
                                        backgroundColor: 'transparent',
                                        width: '100%',
                                        color: 'white',
                                        borderRadius: '7px',
                                        fontWeight: 600,
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </ModalHeader>
                <ModalFooter>
                    <Button
                        onClick={() => props.setShow(false)}
                        style={`
                            border: 0;
                            background-color: rgb(98 98 98);
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="warning"
                        style={`
                            margin-left: 20px;
                            width: 25%;
                            transition: none;
                        `}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </StyledModal>
    )
}

export default EditClipNameModal
