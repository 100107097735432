import { Image } from 'components/Image'
import { Col, Row } from 'components/Layout'
import { Text } from 'components/Text'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { ContentWrapper } from './support.style'

const SupportSection: React.FC = (props: any) => {
    const { partners, sponsors } = props

    return (
        <>
            <Text fColor="white" fSize={1.5} fWeight={700} mode="h2">
                {'Proudly Supported By'}
            </Text>

            <ContentWrapper>
                <Text fColor="white" fSize={1.0625}>
                    {'Major Sponsors'}
                </Text>
                <Row justifyContent="flex-start" alignItems="center" gap={50}>
                    {sponsors &&
                        sponsors.map((item: any, index: number) => {
                            const { logo, name } = item.sponsor
                            return (
                                <Col key={index}>
                                    <Image
                                        src={logo}
                                        oFit="contain"
                                        width={100}
                                        height={80}
                                        alt={name}
                                    />
                                </Col>
                            )
                        })}
                </Row>
            </ContentWrapper>

            <ContentWrapper>
                <Text fColor="white" fSize={1.0625}>
                    {'Partners'}
                </Text>
                <Row justifyContent="flex-start" alignItems="center" gap={20}>
                    {partners &&
                        partners.map((item: any, index: number) => {
                            const { logo, name } = item.partner
                            return (
                                <Col key={index}>
                                    <Image
                                        src={logo}
                                        oFit="contain"
                                        width={100}
                                        height={80}
                                        alt={name}
                                    />
                                </Col>
                            )
                        })}
                </Row>
            </ContentWrapper>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    partners: state.club.info.club_partners,
    sponsors: state.club.info.club_sponsors,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SupportSection)
