import { useMutation } from '@apollo/client'
import { useUser } from '@auth0/nextjs-auth0'
import d_photo from 'assets/images/player/default-player-image.png'
import { ImageCrop_Modal } from 'components/Modal'
import { PLAYERQL, USERQL } from 'graphql/club'
import { PlayerContext } from 'pages/club/[club_slug]/player/[player_slug]'
import React, { useContext, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'
import { s3UploadFile } from 'utils/s3-helper'
import { CustomForm, ProfileWrapper } from './Intro.style'
import DeskClub from './club/desktop'
import DeskProfile from './profile/desk'

const IntroSection: React.FC = (props: any) => {
    const { player } = useContext<any>(PlayerContext)
    const { teams } = props
    const { user } = useUser()

    const [meta, setMeta] = useState<any>(null)
    const [flag, setFlag] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [isSubmit, setisSubmit] = useState<boolean>(false)
    const [imageSrc, setiimageSrc] = useState<any>(
        player?.user?.photo ? player?.user?.photo : d_photo,
    )
    const [store, setStore] = useState<any>(null)

    const [userUpdate] = useMutation(USERQL.UPDATE_USERS, {
        onCompleted() {
            setiimageSrc(store)
            setShow(false)
            toast.success('Success.')
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const [update] = useMutation(PLAYERQL.UPDATE_USER_PLAYERS, {
        onCompleted() {
            setisSubmit(false)
            setFlag(false)
            toast.success('Success.')
        },
        onError(e) {
            toast.error('Error Happened.')
            setisSubmit(false)
        },
    })

    const onFinish = async (values: any) => {
        const { first_name, last_name, ...rest } = values

        setisSubmit(true)

        await update({
            variables: {
                pid: player.id,
                uid: player.user.id,
                po_object: {
                    ...rest,
                    positions:
                        rest && rest.positions ? rest.positions.split(',') : '',
                },
                user_object: {
                    first_name,
                    last_name,
                },
            },
        })

        setFlag(false)
    }

    const saveImage = async (file: File, imageSrc: any) => {
        setStore(imageSrc)
        let photo: string | null = null
        const s3res: any = await s3UploadFile('Players', player.slug, file)
        photo = s3res.location
        setiimageSrc(`${s3res.location}?${Math.random()}`)
        await userUpdate({
            variables: { uid: player.user.id, object: { photo } },
        })
    }

    const onTargetClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }

    const onFileInputChange = (e: any) => {
        setMeta(e)
        setShow(true)
    }

    const onClickFollow = (e: any) => {
        setShow(user ? false : true)
    }

    if (!player) {
        return <></>
    }

    const option = {
        onClickFollow,
        teams,
        show,
        onFileInputChange,
        onTargetClick,
        flag,
        setFlag,
        isSubmit,
        imageSrc,
        fileInputRef,
    }

    return (
        <>
            <CustomForm
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    first_name: player?.user?.first_name ?? '',
                    last_name: player?.user?.last_name ?? '',
                    bio: player.bio,
                    team_id: player.teams[0]?.id,
                    debut_date: player.debut_date,
                    positions: '',
                    prev_club: player.prev_club,
                }}
            >
                <ProfileWrapper>
                    <DeskProfile option={option} />
                </ProfileWrapper>

                <DeskClub />
            </CustomForm>
            <ImageCrop_Modal
                show={show}
                meta={meta}
                saveImage={saveImage}
                handleClose={() => setShow(false)}
            />
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    teams: state.teams.list,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(IntroSection)
