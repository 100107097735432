import { useMutation } from '@apollo/client'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

// import styles
import {
    CustomText,
    UploadContentWrapper,
    UploadFileWrapper,
} from '../mediagallery.style'

// import assets
import { UploadImageIcon, UploadVideoIcon } from 'assets/icon'

// import util
import { slugifyString } from 'utils/common-helper'
import { s3UploadFile } from 'utils/s3-helper'

// import gql
import { mutate } from 'graphql/mediaGallery'

// context
import { MediaGalleryContext } from '../index'

// hooks
import { useInsertActivityFeed, useUser } from 'hooks'
import { RootState } from 'redux/reducers/rootReducer'

const UploadImage = (props: any) => {
    const { mode, playerId } = useContext(MediaGalleryContext)
    const { clubInfo, type, fileTypes } = props
    const [isUploading, setIsUploading] = useState(false)
    const { user }: { user: any } = useUser()
    const router = useRouter()
    const { asset_id } = router.query

    const insertFeed = useInsertActivityFeed()

    const [inserMediaFile] = useMutation(mutate.ADD_MEDIA_FILE, {
        onCompleted() {
            toast.success(`Added ${type} to the media gallery`)
        },
        onError(e) {
            toast.error(`Error while adding ${type} to the media gallery` + e)
        },
    })

    const [addTagClub] = useMutation(mutate.ADD_CLUB_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const [addTagPlayer] = useMutation(mutate.ADD_PLAYER_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const onFinish = async (file: File) => {
        const slug = slugifyString(file.name)
        let mediaFile: string | null = null

        if (!_.isNull(file)) {
            //10MB = 10000KB = 10000000 bytes
            if (file.size > 10000000) {
                toast.warning('File exceeded the maximum size of 10mb')
                return
            }

            try {
                setIsUploading(true)
                const s3res: any = await s3UploadFile(
                    'MediaGallery',
                    slug,
                    file,
                )
                mediaFile = s3res.location

                const mediaFileObject = {
                    club_id: clubInfo.id,
                    match_id: asset_id,
                    url: mediaFile,
                    added_by: user?.id,
                }

                const { data } = await inserMediaFile({
                    variables: {
                        objects: mediaFileObject,
                    },
                })

                // insert activity feed
                if (!_.isEmpty(user?.players_details)) {
                    const feedInput = {
                        player_id: user?.players_details[0]?.id ?? null,
                        type: 'media_gallery',
                        ref_id: data.insert_media_gallery.returning[0].id,
                        action: 'upload',
                    }

                    await insertFeed([feedInput])
                }

                // tag player
                if (mode === 'player' && playerId) {
                    const mediaGalleryInfo =
                        data.insert_media_gallery.returning[0] ?? null

                    const mediaTagPlayerObj = {
                        player_id: playerId,
                        media_gallery_id: mediaGalleryInfo?.id,
                    }

                    const { data: playerTag } = await addTagPlayer({
                        variables: {
                            objects: mediaTagPlayerObj,
                        },
                    })

                    const mediaPlayerTagId =
                        playerTag.insert_media_gallery_players.returning[0].id

                    // insert activity feed for tagging player
                    const feedInput = {
                        player_id: playerId,
                        type: 'media_gallery',
                        ref_id: mediaPlayerTagId,
                        action: 'tag',
                    }

                    await insertFeed([feedInput])
                }

                // tag club
                if (mode === 'club' || mode === 'player') {
                    const mediaGalleryInfo =
                        data.insert_media_gallery.returning[0] ?? null

                    const mediaTagClubObj = {
                        club_id: clubInfo.id,
                        media_gallery_id: mediaGalleryInfo?.id,
                    }

                    await addTagClub({
                        variables: {
                            objects: mediaTagClubObj,
                        },
                    })
                }
            } catch (e) {
                console.log(`Upload ${type} error: `, e)
                toast.error(
                    `Error while adding ${type} to the media gallery` + e,
                )
            } finally {
                setIsUploading(false)
            }
        }
    }

    return (
        <UploadFileWrapper>
            <FileUploader
                handleChange={(file: File) => onFinish(file)}
                name={type}
                types={fileTypes}
                disabled={isUploading}
            >
                <UploadContentWrapper>
                    {type === 'image' ? (
                        <UploadImageIcon iColor="white" />
                    ) : (
                        <UploadVideoIcon iColor="white" />
                    )}
                    <CustomText>
                        {isUploading
                            ? `Currently uploading ${type}...`
                            : 'Upload or drag a file right here'}
                    </CustomText>

                    <CustomText>
                        {!isUploading && fileTypes.toString()}
                    </CustomText>
                </UploadContentWrapper>
            </FileUploader>
        </UploadFileWrapper>
    )
}

const mapStateToProps = (state: RootState) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(UploadImage)
