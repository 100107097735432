import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const schema = z.object({
    search: z.string().optional(),
    sport: z.string().optional(),
})

export type SearchSchema = z.infer<typeof schema>

const eventName = 'search.input'

export function searchInputEvent({
    input,
    gtm,
    datadog,
}: {
    input: SearchSchema
    gtm?: boolean
    datadog?: boolean
}) {
    gtm &&
        raiseEvent({
            type: eventName,
            originator: 'Home',
            payload: schema.parse(input),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            searchInput: input,
        })
}
