import { Image } from 'components/Image'
import { BannerFade, BannerImageWrapper, BannerSpacer } from './banner.style'

const PageBanner = (props: any) => {
    if (!props?.image) return <></>
    return (
        <BannerSpacer>
            <BannerImageWrapper>
                {props?.image && (
                    <Image
                        src={props?.image}
                        oFit="cover"
                        mode="fill"
                        alt=""
                        quality={100}
                    />
                )}
                <BannerFade />
            </BannerImageWrapper>
        </BannerSpacer>
    )
}

export default PageBanner
