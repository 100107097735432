import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const schema = z.object({
    search: z.string().optional(),
    sport: z.string().optional(),
})

export type FilterSchema = z.infer<typeof schema>

const eventName = 'sports.filter'

export function sportsFilterEvent({
    filter,
    gtm,
    datadog,
}: {
    filter: FilterSchema
    gtm: boolean
    datadog: boolean
}) {
    gtm &&
        raiseEvent({
            type: eventName,
            originator: 'Home',
            payload: schema.parse(filter),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            sportsFilter: filter,
        })
}
