import styled from 'styled-components'

export const SocialShareContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-flow: column;
`

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`
