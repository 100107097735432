import { getSession } from '@auth0/nextjs-auth0'
import { initializeApolloFromContext } from 'api/apollo'
import { FlexContainer, WithContainer } from 'components/Container'
import { Col } from 'components/Layout'
import { Page } from 'components/Page'
import { PLAYERQL } from 'graphql/club'
import { query } from 'graphql/initialData'
import { siteSettings } from 'hooks'
import type { GetServerSideProps } from 'next'
import React, { createContext } from 'react'
import { MediaGalleryView } from 'sections/club/home'
import {
    ClipSection,
    IntroSection,
    PlayerProfileLive,
    RecentGames,
} from 'sections/club/player'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import { fetchUserInfo } from 'utils/helper-user-info'

const PlayerContentWrapper = styled(FlexContainer)`
    flex-direction: row;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-direction: column;
    }
`

export const PlayerContext = createContext<any>(null)

const PlayerPage: React.FC = (props: any) => {
    const { player, club_slug, initialData, meta, userInfo } = props

    return (
        <Page {...meta}>
            <PlayerContext.Provider value={{ player }}>
                <WithContainer
                    mode="container"
                    SectionView={IntroSection as any}
                />
                <PlayerContentWrapper width="100%">
                    <FlexContainer order={2}>
                        <Col>
                            <WithContainer
                                mode="container"
                                SectionView={ClipSection}
                                sectionProps={{
                                    clubId: player.club.id,
                                    playerDetailId: player.id,
                                    page: 'Player Profile Page',
                                    event: 'View Clip',
                                    player: player,
                                    club_slug: club_slug,
                                    initialData: { initialData },
                                }}
                            />
                            <WithContainer
                                mode="container"
                                SectionView={RecentGames}
                                sectionProps={{
                                    playerDetailId: player.id,
                                    page: 'Player Profile Page',
                                    event: 'View Replay',
                                    initialData: { initialData },
                                }}
                            />
                        </Col>
                    </FlexContainer>
                </PlayerContentWrapper>
                {siteSettings('club_page.media_gallery') && (
                    <WithContainer
                        mode="container"
                        SectionView={MediaGalleryView}
                        sectionProps={{
                            mode: 'player',
                            clubId: player.club.id,
                            playerId: player.id,
                        }}
                    />
                )}
                <WithContainer
                    mode="container"
                    SectionView={PlayerProfileLive as any}
                    sectionProps={{
                        page: 'Player Profile Page',
                        event: 'View Live Match',
                        initialData: { initialData },
                    }}
                />
            </PlayerContext.Provider>
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const apolloClient = initializeApolloFromContext(context)
    const { club_slug, player_slug } = context.query
    const sess: any = getSession(context.req, context.res)
    const user = sess?.user
    const userInfo = user && (await fetchUserInfo(apolloClient, user))

    const { data } = await apolloClient.query({
        query: PLAYERQL.GET_PLAYER,
        variables: {
            where: {
                slug: { _eq: player_slug },
                club: { slug: { _eq: club_slug } },
            },
        },
    })

    const player = data.players_details[0]

    const { data: activityData } = await apolloClient.query({
        query: query.GET_ACTIVITY_FEED,
        variables: {
            where: { players_detail: { slug: { _eq: player_slug } } },
        },
    })

    const { data: clips } = await apolloClient.query({
        query: query.GET_PLAYER_FEATURE_CLIPS,
        variables: {
            where: {
                players_id: { _eq: player.id },
                club_id: { _eq: player.club.id },
            },
        },
    })

    const { data: recent } = await apolloClient.query({
        query: query.GET_PLAYER_MATCHES,
        variables: {
            where: {
                stream_info: {
                    status: { _eq: 'completed' },
                    archived: { _neq: true },
                },
                home_team: {
                    players: {
                        id: { _eq: player.id },
                    },
                },
            },
        },
    })

    const { data: liveUpcoming } = await apolloClient.query({
        query: query.GET_PLAYER_MATCHES,
        variables: {
            where: {
                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: { _neq: true },
                },
                club_id: { _eq: player.club.id },
                _or: [
                    { away_club_id: { _eq: player.club.id } },
                    { home_team: { club_id: { _eq: player.club.id } } },
                ],
            },
        },
    })

    const playerName =
        player?.name && player?.last_name
            ? `${player.name} ${player.last_name}`
            : player?.name
              ? player.name
              : null

    return {
        props: {
            player: player,
            activityFeedCount: activityData.activity_feed.length,
            club_slug: club_slug,
            initialData: {
                recent: recent?.matches,
                liveUpcoming: liveUpcoming?.matches,
                clips: clips?.clip_asset_players,
            },
            meta: {
                title: `${playerName} - Player Profile`,
                description: `Watch all the action from ${playerName}. Live streams, replays, highlights and more.`,
                image: player?.image ?? null,
            },
            userInfo: userInfo ?? null,
        },
    }
}

export default PlayerPage
