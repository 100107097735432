import { useMutation, useSubscription } from '@apollo/client'
import { useUser } from '@auth0/nextjs-auth0'
import { Popover } from 'antd'
import { Image } from 'components/Image'
import { SocialShare } from 'components/SocialShare'
import { Text } from 'components/Text'
import { USERS_FOLLOW_TEAM } from 'graphql/club/teams'
import { mutate } from 'graphql/user'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { FiShare2, FiUserMinus, FiUserPlus } from 'react-icons/fi'
import { connect } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { baseUrl } from 'utils/constData'
import { isServer } from 'utils/helper'
import {
    FollowButton,
    FollowerText,
    HeadClubName,
    HeadWrapper,
    ShareButton,
} from './head.style'

const HeadView: React.FC = (props: any) => {
    const router = useRouter()
    const { asPath } = router
    const { team_slug } = router.query
    const { initialData } = props
    const club = initialData?.initialData?.club
    const team = _.find(club.teams, ['slug', team_slug])
    const { user } = useUser()

    const [followed, setFollowed] = useState(false)
    const [followActive, setFollowActive] = useState(true)
    const [followerCount, setFollowerCount] = useState(0)

    const [follow] = useMutation(mutate.USER_FOLLOW_TEAM)
    const [unfollow] = useMutation(mutate.USER_UNFOLLOW_TEAM)

    useSubscription(USERS_FOLLOW_TEAM, {
        skip: isServer || !team.id,
        variables: {
            where: {
                team_id: { _eq: team.id },
            },
        },
        onData: ({ data: { data, loading } }) => {
            if (data.user_team_follows) {
                setFollowActive(true)
                setFollowed(
                    user &&
                        data.user_team_follows
                            .map((t: { user_id: any }) => t.user_id)
                            .includes(user.id),
                )
                setFollowerCount(data.user_team_follows.length)
            }
        },
    })

    const handleFollow = () => {
        if (user) {
            setFollowActive(false)
            if (followed) {
                unfollow({
                    variables: {
                        where: {
                            user_id: { _eq: user.id },
                            team_id: { _eq: team.id },
                        },
                    },
                })
            } else {
                follow({
                    variables: {
                        team_objects: {
                            user_id: user.id,
                            team_id: team.id,
                        },
                        club_objects: {
                            user_id: user.id,
                            club_id: club.id,
                        },
                    },
                })
            }
        } else {
            router.push(`/api/auth/login?returnTo=${asPath}`)
        }
    }

    if (_.isEmpty(club)) {
        return <></>
    }

    return (
        <HeadWrapper>
            <div className="name-wrapper">
                <Image
                    src={team?.image ? team?.image : club?.logo}
                    width={89}
                    height={90}
                    alt=""
                />
                <HeadClubName>
                    <Text
                        fColor="white"
                        fWeight={800}
                        className="col-md"
                        mode="h1"
                    >
                        {club?.name} {team?.name}
                    </Text>
                </HeadClubName>
            </div>

            <div className="button-wrapper">
                {club && (
                    <FollowerText>
                        <Text fSize={1}>{`${followerCount} Followers`}</Text>
                    </FollowerText>
                )}

                <FollowButton
                    onClick={handleFollow}
                    bColor={followed ? 'gray' : 'warning'}
                    icon={followed ? <FiUserMinus /> : <FiUserPlus />}
                    disabled={!followActive}
                >
                    {followed ? 'Unfollow' : 'Follow Team'}
                </FollowButton>

                <Popover
                    content={<SocialShare url={`${baseUrl + router.asPath}`} />}
                    trigger="click"
                >
                    <ShareButton
                        margin="10px"
                        bColor="primary"
                        bSize="small"
                        icon={<FiShare2 />}
                    >
                        {'Share'}
                    </ShareButton>
                </Popover>
            </div>
        </HeadWrapper>
    )
}

const mapStateToProps = (state: RootState) => ({
    club: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(HeadView)
