import React from 'react'
import { Skeleton } from 'antd'
// styled component
import { CardWrapper, CardContent } from 'theme/global.state'
import { StyleContent } from './index.style'

const MediaGallerySkeleton: React.FC<{ mode?: string }> = ({
    mode = 'image',
}) => {
    return (
        <CardWrapper>
            <CardContent>
                <StyleContent>
                    <Skeleton.Button active block />
                </StyleContent>
            </CardContent>
        </CardWrapper>
    )
}

export default MediaGallerySkeleton
