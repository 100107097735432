import { ScrollingCarousel } from 'components/ReactCarousel'
import { useContext } from 'react'

import _ from 'lodash'

// import styles
import { MediaBody } from 'theme/global.state'
import { SectionWrapper } from '../mediagallery.style'

// import components
import { Row } from 'components/Layout'
import { MediaGallerySkeleton } from 'components/Skeleton'
import { Text } from 'components/Text'
import { EmptyMedia, UploadVideo, Videos } from '../components'

// import constants
import { VideoFileTypes } from '../constants'

// context
import { MediaGalleryContext } from '../index'

const VideoLayout = () => {
    const { videos, mediaGalleryAccess, loading } =
        useContext(MediaGalleryContext)

    if (_.isEmpty(videos) && !mediaGalleryAccess && !loading) return <></>

    return (
        <SectionWrapper>
            <Row flexDirection="column">
                <Text
                    fColor="white"
                    fSize={1}
                    fWeight={700}
                    mode="p"
                    padding="5px"
                >
                    {'Videos'}
                </Text>
                {loading ? (
                    <div style={{ padding: '10px' }}>
                        <ScrollingCarousel type="Media">
                            {[1, 2, 3, 4, 5, 6, 7].map((item: number) => {
                                return (
                                    <MediaBody key={`image-key-${item}`}>
                                        <MediaGallerySkeleton />
                                    </MediaBody>
                                )
                            })}
                        </ScrollingCarousel>
                    </div>
                ) : videos.length > 0 ? (
                    <Videos />
                ) : mediaGalleryAccess ? (
                    <UploadVideo type="video" fileTypes={VideoFileTypes} />
                ) : (
                    <EmptyMedia content="No videos uploaded" />
                )}
            </Row>
        </SectionWrapper>
    )
}

export default VideoLayout
